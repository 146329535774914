.facility-modal {
  .modal-content {
    max-width: 900px !important;
  }
}

.facility-modal,
.location-modal {
  transition: all 0.3s;

  .modal-content {
    max-width: 900px;
    border-radius: 5px;
    padding: 0;

    margin-bottom: 60px;

    .modal-header {
      background: #f6f6f6;
      padding: 10px;

      h3 {
        font-size: 20px;
        border-top: 0;
      }
    }

    .modal-body {
      padding: 0 10px 10px;

      .react-tabs__tab-panel--selected {
        min-height: 100px;
      }
    }
  }
}

.facility-groups,
.facility-plans {
  // &--list {
  // }

  &--item {
    &__content {
      height: 100%;
    }

    input[type="radio"] {
      display: none;

      &:checked ~ .facility-groups--item__content .facility-group,
      &:checked ~ .facility-plans--item__content .facility-plan {
        box-shadow: 0 0.5rem 1rem rgba(41, 42, 52, 0.15);
        border-color: #7cfc00 !important;
      }
    }
  }
}

.facility-group,
.facility-plan {
  padding: 0;
  box-shadow: 0 0.5rem 1rem rgba(41, 42, 52, 0.15);

  border: 3px solid #fff !important;
  transition: all 0.3s;
  overflow: hidden;
  cursor: pointer;
  height: 100%;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(41, 42, 52, 0.15);
    border-color: #7cfc00 !important;
  }

  .card-body {
    background-color: #fff;
  }

  &--title {
    text-align: left;
    display: block;
    margin: 0;
    padding: 0;
    margin-bottom: 30px !important;
    color: #292a34;
  }
}

.facility-list,
.plan-list {
  &--item {
    background: url("../../assets/img/tick.png") no-repeat left;
    background-color: transparent !important;
    padding: 0 !important;
    padding-left: 25px !important;
    margin-bottom: 20px;
    border: 0 !important;

    line-height: 1;

    text-align: left;
    font-family: Helvetica;
    font-size: 16px;
    color: #292a34;
    letter-spacing: 0.32px;

    &:last-of-type {
      margin-bottom: 10px;
    }
  }
}

.error {
  .picky__input {
    border-color: #ff0000;
  }
}
