@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:500%7CNoto+Sans:700,regular);

.advantage-page {
  .bg_image_adv {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    position: relative;
  }

  .char-count-adv {
    font-size: 10px;
    float: right;
    display: block;
  }

  .char-count-adv+div::after {
    content: "";
    display: block;
    clear: both;
  }

  .cover_header {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .header {
    padding-top: 3%;
    max-height: 700px;

    h1 {
      margin-top: 24%;
      font-weight: 700;
      font-family: "Noto Sans";
      font-style: normal;
      font-size: 48px;
      color: rgb(255, 255, 255);
    }

    p {
      line-height: 34px;

      span {
        font-weight: 900;
        font-family: "Noto Sans";
        font-size: 20px;
        color: rgb(255, 255, 255);
      }
    }



  }

  .topForm {
    border-radius: 5px 5px 0 0;
    background-color: #ffffff;
    box-shadow: 0 1px 136px #00000021;
    width: 90%;

    .formHead {
      border-radius: 5px 5px 0 0;
      background-color: rgba(219, 238, 215, 1);

      p {
        padding: 3% 15%;
        text-align: center;
        line-height: 29px;

        span {
          font-weight: 700;
          font-family: "Noto Sans";
          font-size: 21px;
          color: rgb(19, 21, 13);
          font-style: normal;
        }
      }
    }

    .formBody {
      width: 90%;
      margin: auto;
      margin-top: 7%;
      padding-bottom: 7%;

      label {
        font-family: Noto Sans;
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;
        color: #000;
        display: block;
        margin-bottom: 4px;
        width: auto;
        margin-right: 0px;
      }

      input {
        border-style: solid !important;
        border-width: 1px !important;
        border-color: #bbbbbb !important;
        background-color: #fff !important;
        color: #000;
        border-radius: 5px !important;
        height: 50px;
        font-size: 15px !important;
        line-height: 15px;
        padding-left: 16px !important;
        padding-right: 16px !important;
        flex: 1;
      }

      textarea {
        height: 107px;
        line-height: 18px;
        padding-top: 16px;
        padding-bottom: 17px;
        border-style: solid !important;
        border-width: 1px !important;
        border-color: #bbbbbb !important;
        background-color: #fff !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
        flex: 1;
      }

      .button_c {
        display: block;
        margin: auto;
        border-style: none;
        border-radius: 5px;
        width: 440px;
        height: 50px;
        background: rgba(154, 211, 8, 1);
        box-shadow: none;
        text-shadow: none;
        color: #fff;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        font-family: Noto Sans JP;
        font-style: normal;
        text-align: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
  }

  .content_page {
    margin-top: 15%;

    h1 {
      font-weight: 700;
      font-family: "Noto Sans";
      font-size: 34px;
      color: rgb(19, 21, 13);
      font-style: normal;
      line-height: 52px;
      text-align: center;
      text-size-adjust: none;
    }

    .hr {
      background: rgba(154, 211, 8, 1);
      border-style: none;
      border-radius: 0px;
      z-index: 13;
      width: 1020px;
      height: 2px;
      margin: auto;
    }



    .content_item {
      margin-top: 12%;

      div {
        display: block;
        background: rgba(219, 238, 215, 1);
        border-style: solid;
        border-width: 1px;
        border-color: #ddd;
        border-radius: 5px;
        width: 98px;
        height: 98px;
        margin: auto;
      }

      img {
        width: 42px;
        height: 42px;
        display: block;
        margin: 30% !important;
      }

      h2 {
        font-weight: 700;
        font-family: "Noto Sans";
        font-size: 18px;
        color: rgb(19, 21, 13);
        text-align: center;
        width: 70%;
        margin: auto;
        margin-top: 4%;
      }
    }

    .learn_more_btn {
      display: block;
      border-style: none;
      border-radius: 5px;
      z-index: 54;
      width: 185px;
      height: 58px;
      background: rgba(154, 211, 8, 1);
      box-shadow: none;
      text-shadow: none;
      color: #fff;
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
      font-family: Noto Sans;
      font-style: normal;
      text-align: center;
      cursor: pointer;
      margin: auto;
    }
  }

  input[type="text"].error,
  input[type="password"].error,
  input[type="email"].error,
  textarea.error,
  input[type="number"].error {
    border-color: red !important;
  }

  .mobile_section {
    padding: 6% 0;

    img {
      width: 315px;
      height: 597px;
      display: block;
      margin: auto;
    }

    .text_content {
      width: 424px;
      margin: auto;
      margin-top: 15%;
    }

    h1 {
      font-weight: 700;
      font-family: "Noto Sans";
      font-size: 35px;
      color: rgb(19, 21, 13);
      line-height: 52px;
      text-align: left;
    }

    p {
      margin-top: 3%;
      line-height: 24px;
      font-weight: 400;
      font-family: "Noto Sans";
      font-size: 18px;
      color: rgb(19, 21, 13);
      font-style: normal;
    }
  }

  .mobile_section_bg {
    display: block;
    background: #dbeed7;
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    height: 685px;
    padding-top: 3%;
    padding-bottom: 3%;

    img {
      width: 548px;
      height: 575px;

    }

    .text_content {

      margin: auto;
      margin-top: 28%;
    }

    h1 {
      font-weight: 700;
      font-family: "Noto Sans";
      font-size: 35px;
      color: rgb(19, 21, 13);
      line-height: 52px;
    }

    p {
      font-weight: 400;
      font-family: "Noto Sans";
      font-size: 18px;
      color: rgb(19, 21, 13);
      font-style: normal;
      line-height: 24px;
      width: 80%;
    }
  }

  .container-fluid {
    margin: 0 !important;
    padding: 0 !important;
  }

  .content_page2 {
    margin-top: 5%;

    .button_c {
      margin: auto;
      display: block;
      border-style: none;
      border-radius: 100px;
      z-index: 129;
      width: 233px;
      height: 58px;
      background: rgba(154, 211, 8, 1);
      box-shadow: none;
      text-shadow: none;
      color: #fff;
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
      font-family: Noto Sans;
      font-style: normal;
      text-align: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .mobile_section_2 {
      img {
        width: 43%;
        margin: auto;
        display: block;
      }

      .text_content {

        margin: auto;
        margin-top: 20%;

        h1 {
          font-weight: 700;
          font-family: "Noto Sans";
          font-size: 35px;
          color: rgb(19, 21, 13);
          line-height: 52px;
        }

        p {
          font-weight: 400;
          font-family: "Noto Sans";
          font-size: 18px;
          color: rgb(19, 21, 13);
          font-style: normal;
          width: 93%;
        }

        .orange_font {
          color: #f68536 !important;
        }
      }


    }

    .mobile_section_3 {
      h1 {
        font-weight: 700;
        font-family: "Noto Sans";
        font-size: 35px;
        color: rgb(19, 21, 13);
        font-style: normal;
        line-height: 48px;
        width: 406px;
      }

      img {
        width: 649px;
        height: 433px;
        border-style: none;
        border-radius: 15px;
        margin-top: 10%;
      }


      .plan {
        margin: auto;
        background: #9ad308;
       // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        box-shadow: 0 2px 8px 4px #9ad307;
        border-radius: 25px;
        padding-top: 10px;
        padding-left: 10px;
        flex: 0 0 19%;
        width: 80%;
        height: 90%;
        margin-bottom: 20px;
        display: block;

        &.plan-blue {
          background-color: rgba(35, 187, 249, 0.64);

          .inner-plan .sign-up {
            background-color: rgba(35, 187, 249, 0.64);
            border-color: rgba(35, 187, 249, 0.64);
          }

          .radius-circle {
            background-color: rgba(35, 187, 249, 0.64);
          }
        }

        .plan-title {
          font-family: Helvetica;
          font-size: 20px;
          color: #292a34;
          letter-spacing: 0.39px;
          text-align: center;
          line-height: 22px;
          width: 80%;
          margin: auto;

          h2 {
            font-weight: 700;
            font-family: "Noto Sans";
            font-size: 22px;
            color: rgb(0, 0, 0);
            font-style: normal;
          }
        }

        .inner-plan {
          background: #ffffff;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 25px;
          padding: 15px;
          position: relative;
          padding-bottom: 86px;
          overflow: hidden;

          height: 100%;

          .sign-up {
            background: #9ad308;
            border-radius: 20px;
            font-family: Helvetica;
            font-size: 16px;
            color: #ffffff;
            letter-spacing: 0.36px;
            text-align: center;
            max-width: 170px;
            border: 1px solid #9ad308;
            padding: 5px 31px;
            cursor: pointer;
            outline: none;
            height: 36px;
            font-weight: 500;
            position: absolute;
            bottom: 25px;
          }
        }
      }
    }
  }

  .discount {
    position: relative;
    color: grey;
    font-size: 20px;
    margin-right: 15px;

    span {
      display: block;
      position: absolute;
      width: 41px;
      border-bottom: 2px solid gray;
      left: 0;
      top: 10px;
      transform: rotate(339deg);
    }

    .display_visible {
      display: inline;
    }
  }

  .margin_top {
    margin-top: 6%;
  }

  .line {
    margin-top: 7%;
    margin-bottom: 3%;
  }

  .content_page3 {
    h1 {
      font-weight: 500;
      font-family: "Noto Sans JP";
      font-size: 28px;
      color: rgb(0, 0, 0);
      font-style: normal;
      line-height: 38px;
      text-align: center;
      width: 75%;
      margin: auto;

    }

    .custom_border {
      border-bottom: 3px solid #9ad308;
      width: 50%;
      margin: auto;
      margin-top: 1%;
    }

    .button_c {
      display: block;
      text-align: center;
      padding: 0 8px 0 8px;
      cursor: pointer;
      border-style: none;
      border-radius: 100px;
      z-index: 114;
      width: 300px;
      height: 58px;
      margin: auto;
      background: rgba(154, 211, 8, 1);
      box-shadow: none;
      text-shadow: none;
      color: #fff;
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
      font-family: Noto Sans;
      font-style: normal;
      text-align: center;
      background-repeat: no-repeat;
      margin-top: 3%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .advantage-page {
    .topForm {
      margin: auto;

      .formHead {
        p {
          padding: 3% 4%;
        }
      }
    }

    .bg_image_adv {

      height: 1299px;
      //background: black;
      background-size: auto;
      background-position: 68% 55%;
    }

    .header {
      h1 {
        font-size: 40px;
      }
    }

    .button_c {
      width: 100% !important;
    }

    .mobile_section_bg {
      img {
        width: auto;
        height: auto;
      }
    }

    .content_page2 {
      .mobile_section_3 {
        h1 {
          width: auto;
        }
      }
    }

    .mobile_section {
      .text_content {
        width: auto;
      }
    }
  }
}

@media (max-width:960px) {
  .content_page {
    margin-top: 70% !important;

    .hr {
      width: auto !important;
    }
  }

  .content_page2 {
    margin-top: 45% !important;
  }

  .plan {
    width: auto !important;
    margin-top: 3% !important;
  }
}

@media (max-width:690px) {
  .advantage-page {
    .content_page {
      margin-top: 10% !important;
    }
    .content_page3{
      margin-top: 32%;

      h1{
        margin-bottom: 13%;
      }
      
    }
    .mobile_section_2 {
      margin-top: 90%;
    }
  }

}
