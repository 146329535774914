//***************************************************************************************************************************
@media #{$xs} {
  section.mac-section .mac-bottom .mac-text .mac-img img {
    max-width: 100%;
  }

  .modal {
    padding: 0 10px;

    .modal-content {
      width: 100%;
    }
  }

  .modal.listied-modal .modal-content {
    width: 100%;
  }

  .rec-form {
    display: block;

    .form-group {
      margin-bottom: 5px;
    }
  }

  .dashboard .rec-btn ul li button {
    font-size: 14px;
  }

  .pricing .main-container {
    padding: 0 10px;
  }

  .pricing .pricing-block .pricing-content .pricing-card {
    width: 100%;
  }

  .signUp-from .form-group {
    display: block;

    label {
      display: block;
      padding-bottom: 5px;
    }

    input,
    textarea {
      width: 100%;
    }
  }

  .check-group .form-group {
    display: flex;

    input {
      width: inherit;
    }
  }

  .notification span.not-icon {
    top: 25px;
  }

  .notification p {
    margin-bottom: -10px;
  }

  .signUp-from .textarea-group .notification span.not-icon {
    top: 35px;
  }

  .access {
    .notification span.not-icon {
      top: 15px;
    }
  }

  section.main-section .main-content .text-block .main-title {
    padding-bottom: 100px;
  }

  .dashboard .address ul {
    display: block;
    text-align: center;

    li {
      font-size: 15px;

      &:after {
        display: none;
      }
    }
  }

  .image_upload {
    img {
      max-width: 100%;
    }
  }

  .item-chart {
    width: 100%;
    overflow-x: scroll;
  }

  .messages .messages-list ul li .message-block .message-content .message-avatar {
    flex: 0 0 100%;
  }

  .messages .messages-list ul li .message-block .message-content .message-cennter {
    flex: 0 0 100%;
  }

  .messages .messages-list ul li .message-block .message-content .message-right {
    flex: 0 0 100%;
  }

  .search input {
    margin: 0;
    max-width: 80%;
    flex: 0 0 80%;
  }

  .search button {
    min-width: 25px;
    flex: 0 0 25px;
  }

  .modal.members-modal .modal-content {
    width: 100%;
  }

  .dashboard .edit-btn .notification p {
    margin-bottom: 15px;
    width: 290px;
  }
}

//****************************************************************************************************************************
@media #{$sm} {
  
  .dashboard .address ul li {
    font-size: 15px;
    padding: 0 5px;
  }

  .dashboard .edit-btn h2 {
    font-size: 15px;
  }

  .dashboard .table-content .table .dash-body {
    height: calc(100vh - 290px);
  }

  .homepage section.mac-section .mac-bottom .mac-text .mac-img img {
    max-width: 400px;
  }

  .form-group label.label-text {
    font-size: 15px;
  }

  .item-chart {
    width: 525px;
    margin: 0 auto;
  }

  .messages .messages-list ul li .message-block .message-content .message-cennter {
    flex: 0 0 100%;
    order: 3;
  }
}

//**************************************************************************************************************************

@media (max-width: 1250px) {
  .lesson-create-wrap {

    .each-col,
    .left-side,
    .right-side {
      flex: 0 0 100% !important;
      // max-width: 100% !important;
      // padding: 0 !important;
    }
  }
}
@media (max-width: 650px) {
  .lesson-in-court{
    max-width: 100% !important;
    width: 100% !important;

    .micro-settings,.d-flex{
      max-width: 100% !important;
    }
  }
  .pricing_part{
    margin-top: 0;
  }
  .yogaPagePrice{
    .pricePageScale{
      margin-top: -440px;
      margin-bottom: -310px;
    }
  }
  .schedule-form-in-price{
   // margin-bottom: -250px;
   //to fix
  }
  .marginTop-100{
    margin-top: -290px !important;
  }
  .pricePageScale{
    margin-top: -250px;
  }
  table.r-responsive-table th,
  table.r-responsive-table td {
    word-break: break-word !important;

    .groups_option_points{
      position: absolute;
      top: -6px;
      right: 0;
      z-index: 10;
    }
    .groups_options{
      width: 100%;
      left: auto;
      top:27px;
    }
  }
  .res_d-flex {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  header .header-content .header-menu .nav-bar ul li ul {
    position: static;
    border-top-color: rgba(0, 0, 0, 0.08);
  }
  .lessons{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
  }
  header .header-content .header-menu .nav-bar ul li li {
    padding: 0 5px;
  }

  .group .group-item {
    min-width: 100%;
    grid-template-columns: 150px auto;
  }

  .group .group-item .go-to-group {
    grid-column-start: 1;
    grid-column-end: 3;
    border-top: 1px solid #000;
    padding-top: 10px;
    margin-top: 5px;

    .btn {
      margin-left: 20px;
    }
  }

  .GroupItemPage .group-item-update {
    display: block;
  }

  .group-item-update-buutton {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .GroupItemPage .group-item-members-list .group-item-memeber-item {
    grid-template-columns: 50px auto auto auto;

    .lable-parth {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .group-item-desc {
      grid-column-start: 3;
      grid-column-end: 5;
    }

    .go-to-group {
      grid-column-start: 1;
      grid-column-end: 5;
    }

    .appeircheckbox {
      grid-column-start: 1;
      grid-column-end: 5;
    }
  }

  .GroupMemberPage .selfParth div label {
    white-space: nowrap;

    input,
    textarea {
      display: block;
    }
  }

  .GroupMemberPage .parentPart div label {
    white-space: nowrap;

    input {
      display: block;
    }
  }

  .GroupItemPage .group-item-members-list .AppeirParth {
    grid-template-columns: auto auto auto;

    .appeircheckbox {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }

  .notification p {
    right: -10px;
    left: auto;

    &:after {
      right: 10px;
      left: auto;
    }
  }

  .signUp-from {
    width: 100%;
    max-width: 630px;
    padding: 0 15px;
  }

  .app-content.active {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 99;
    }
  }

  .container {
    width: 100% !important;
  }

  .main-container {
    width: 100%;
  }

  header {
    position: relative;
    z-index: 999;
    background-color: #ffffff;
  }

  header .header-content .header-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 998;
    width: 100%;
    background-color: #ffffff;
    display: block;
    padding: 0;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    max-height: 0;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    overflow: hidden;

    &.active {
      max-height: 800px;
      padding: 20px 5px 50px;
      padding-left: 10px !important;
    }
  }

  header .header-content .header-menu .nav-bar ul {
    display: block;
    text-align: center;

    li {
      padding: 15px 0;
    }
  }

  header .header-content .header-menu .sign-btns {
    flex-wrap: wrap;
    justify-content: center;
    width: 200px;
    margin: auto;
  }

  header .header-content .header-menu .sign-btns h4 {
    margin-left: 0;
    margin-right: 5px;
    font-size: 12px;
  }

  header .header-content .header-menu .sign-btns ul {
    display: block;
  }

  header .header-content .header-menu .sign-btns ul li {
    padding: 0 5px;
    display: block;
  }

  header .header-content .header-menu .sign-btns ul li a {
    min-width: 110px;
  }

  header .header-content .header-menu .sign-btns ul li.log {
    display: none;
  }

  header .header-content .header-menu .sign-btns .log-in-xs {
    display: block;
    flex: 0 0 180px;
    max-width: 180px;
    text-align: center;

    p {
      font-size: 14px;
      font-weight: bold;
      color: #313234;
      padding: 10px 0;
    }

    button {
      color: #ffffff;
      background-color: #9ad308;
      border: none;
      text-transform: initial;
      padding: 15px 0;
      font-size: 16px;
      border-radius: 3px;
      display: block;
      width: 100%;
      text-align: center;
      outline: none;
      height: 36px;
      line-height: 5px;
    }
  }

  .modal {
    overflow: hidden;
    overflow-y: auto;
  }

  .modal.auth-modal {
    padding: 0 15px;
  }

  .modal.auth-modal .modal-content {
    width: 100% !important;
    padding: 50px 0;

    .auth-container {
      width: 100%;
      margin: 0 auto;
      padding: 0 25px;
    }
  }

  header .header-content .header-menu-btn {
    display: block;

    button {
      background-color: transparent;
      border: none;
      outline: none;

      ul {
        li {
          list-style: none;
          width: 22px;
          height: 2px;
          background-color: #313234;
          transition: all 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);

          &.item2 {
            margin: 4px 0;
          }
        }
      }
    }

    &.close {
      button {
        ul {
          li {
            width: 28px;

            &:first-child {
              transform: rotate(45deg) translateY(5px) translateX(5px);
            }

            &.item2 {
              opacity: 0;
            }

            &:last-child {
              transform: rotate(135deg) translateY(3px) translateX(-4px);
            }
          }
        }
      }
    }
  }

  .access .notification span.not-icon {
    top: -5px;
  }

  .form-group .char-count {
    top: 0;
    right: 0;
    left: auto;
  }

  .signUp-from .radio-group,
  .signUp-from .check-group {
    display: block;
  }

  section.main-section .main-content .image-block {
    display: none;
  }

  section.main-section {
    height: 575px;
    background-position: 70%;
    min-height: auto;
  }

  section.main-section .main-content {
    position: relative;
    z-index: 1;
  }

  section.main-section .main-content .text-block .main-title h2 {
    margin-right: 0;
  }

  section.mac-section {
    padding-top: 60px;
    padding-bottom: 0;
  }

  section.mac-section .mac-images .iphone-c-img,
  section.mac-section .mac-images .mac-img {
    display: none;
  }

  section.mac-section .mac-images {
    margin-bottom: 15px;
  }

  section.mac-section .mac-bottom .mac-text {
    display: block;
  }

  section.mac-section .mac-bottom .mac-text .iphone-c-img,
  section.mac-section .mac-bottom .mac-text .mac-img {
    display: block;
    text-align: center;
  }

  section.mac-section .mac-bottom .mac-text .iphone-c-img {
    img {
      max-width: 220px;
    }
  }

  section.mac-section .mac-bottom .mac-text .col {
    max-width: 100%;
    padding-bottom: 30px;
  }

  section.blue-section .blue-content {
    display: block;
  }

  section.blue-section .blue-content .blue-text {
    max-width: 100%;
  }

  section.blue-section .blue-content .blue-img {
    max-width: 100%;
    text-align: center;

    img {
      max-width: 270px;
    }
  }

  section.section-d .section-d-content {
    flex-wrap: wrap;
  }

  section.section-d .section-d-content .section-d-text {
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }

  section.section-d .section-d-content .section-d-img {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    text-align: center;

    img {
      max-width: 270px;
    }
  }

  .pricing {
    padding: 40px 0;
  }

  .pricing .pricing-block {
    width: 100%;
    flex-direction: column;
  }

  .pricing .pricing-block .pricing-title h2 {
    font-size: 28px;
  }

  .pricing .pricing-block .pricing-content {
    padding: 30px 20px;
    width: 100%;
    justify-content: center;
  }

  .pricing .pricing-block .pricing-content .pricing-card {
    padding: 30px 20px;
    flex: 0 0 100%;
  }

  .analytics-content {
    display: block;

    .analytics-item {
      max-width: 100%;
      text-align: center;
    }
  }

  .analytics-content .analytics-item:first-child {
    border: none;
  }

  .messages .messages-list ul li .message-block .message-content .message-avatar .avatar-name ul li {
    padding: 0;
  }

  .messages .messages-list ul li .message-block .message-content {
    flex-wrap: wrap;
  }

  .messages .plus-icon {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  footer .footer-content {
    display: block;
    text-align: center;

    .footer-logo {
      text-align: left;
    }
  }

  footer .footer-content .footer-menu ul {
    padding-bottom: 40px;
    display: block;
  }

  footer .footer-content .footer-menu ul li {
    padding: 10px 0;
  }

  footer .footer-content .footer-contact ul {
    justify-content: center;

    li {
      padding: 0 10px;
    }
  }

  footer .footer-copyright {
    padding-top: 60px;
    justify-content: center;

    p {
      padding: 0 10px;

      &:first-child {
        display: none;
      }
    }
  }

  // .dashboard .address .info_address ul {
  //   flex-wrap: wrap;
  // }

  .dashboard .address {
    flex-wrap: wrap;
    justify-content: center;
  }

  header .header-content .header-menu .nav-bar ul.submenu {
    position: static;
  }

  .container .lessons .lessons-list .lesson-item {
    display: block;
    margin-right: 10px;
  }

  // .container .lessons .lessons-dataPicker {
  //   order: 1;
  // }

  // .container .lessons .lessons-list {
  //   order: 2;
  // }

  // .container .lessons .lessons-list {
  //   flex: 0 0 100%;
  // }

  .messages .messages-list ul li .message-block .message-content .message-avatar .avatar-name {
    padding-left: 0;
  }

  .messages .messages-list ul {
    overflow: auto;
  }

  .messages .messages-list ul li {
    min-width: 100%;
  }

  .charge-wrapp {
    display: block;
    margin: auto;
  }

  .lessons-quantity {
    span {
      width: 50%;
      max-width: 50%;
      display: inline-block;
    }
  }

  .charge-wrapp .charge-col,
  .form-enlarge-container {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 0 10px !important;
    margin-right: 0;

    input[type='text'] {
      width: 100%;
      margin-bottom: 10px;
      max-width: 100%;
    }
  }

  .border-left-2-gray {
    border-right: 0;
  }

  .charge-wrapp .history-list {
    height: auto;
  }

  .flex-col-100 {
    flex-wrap: wrap;
  }

  .flex-col-50 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;

    input {
      width: 100%;
      margin-right: 0px;
      max-width: 100%;
      flex: 0 0 100%;
    }

    .picky-container-20 {
      flex: 0 0 100%;
      width: 100%;
      margin-right: 0px;
      max-width: 100%;
    }

    .charge-history {
      margin: 0 auto;
    }
  }

  .modal.lessons-modal .modal-content {
    max-width: 95%;
  }

  .lesson-create-wrap {
    .rec-form {
      display: flex;
    }

    td:first-child .lesson-form-group {
      margin-right: 5px;
    }

    // .p-rel {
    //   position: static;
    // }

    .each-col,
    .left-side,
    .right-side {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      padding: 10px !important;
    }

    .form-group {
      flex: 0 0 100% !important;

      align-items: center;
      justify-content: left;
    }
  }

  .tdBefore {
    position: relative;

    .notification span.not-icon {
      top: 0;
    }

    .notification p {
      right: auto;
      margin-bottom: 0;

      &:after {
        right: 80px;
      }
    }
  }

  .signUp-from {
    h3 {
      text-align: center;
      margin-bottom: 10px;
    }

    .form-group,
    .check-group {
      .notification {
        position: absolute;
        right: 25px;
        top: 24px;
      }
    }
  }

  .r-responsive-table td {
    min-height: 35px !important;
  }

  .detailsWrap .booked-by-section {
    border: none;
  }

  .detailsWrap .operation-buttons {
    padding: 0 15px;
  }

  table.minimalistBlack td,
  table.minimalistBlack th {
    padding: 8px 5px;
    font-size: 11px !important;
  }

  .homepage {
    .info-section {
      .withimg {
        flex-direction: column-reverse !important;
      }

      .message-section {
        flex-direction: column;

        .text-section,
        .text-section-right {
          flex: 0 0 100%;
          padding-right: 0;
          margin-bottom: 15px;
          padding-left: 0;
        }

        .img-section {
          flex: 0 0 100%;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  #app .app-content {
    .modal.calendly {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;

      .modal-content {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;

        .auth-container {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;

          .close-auth-modal {
            bottom: unset;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 999;
          }

          #schedule_form {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }

  /* 
  * Responsive adaptation
  */

  #app .app-content {
    .dashboard .address ul li {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      border-top: 1px solid #000;

      &:first-child {
        margin-top: 10px;
      }
    }

    .modal .modal-content {
      margin-bottom: 60px;
      min-width: unset;
    }

    table tbody td {
      // font-size: 3vw !important;
      white-space: nowrap;

      font-size: 14px !important;

      img {
        max-width: unset;
      }
    }

    .lessons-list {
      max-width: 100%;

      .filters {
        display: block;
        width: 100%;

        .each-filter {
          max-width: 80%;
          margin-right: auto;

          &:first-child+.each-filter {
            margin-top: 20px;
          }
        }
      }
    }

    .search.charge-wrapp .pretty-button {
      margin-left: 0 !important;
    }

    .members-list table tr td .pretty-button {
      max-width: 50%;
      margin-left: auto !important;
    }

    .email-info span.not-icon {
      left: 60px;
      top: -45px;
    }

    .stripe-connect.charge-col table tbody tr td {

      .picky-container,
      .form-group {
        max-width: 70%;
        margin-left: auto;
      }
    }
  }
}

//**************************************************************************************************************************
@media #{$md} {
  .container {
    width: 738px;
  }

  .main-container {
    width: 738px;
  }

  .dashboard .table-content .table .dash-body {
    height: calc(100vh - 300px);
  }

  header .header-content .header-menu .nav-bar ul li {
    padding: 0 5px;
  }

  header .header-content .header-menu .sign-btns h4 {
    display: none;
  }

  header .header-content .header-menu .sign-btns ul li {
    padding: 0 5px;
  }

  header .header-content .header-menu .nav-bar ul li a,
  header .header-content .header-menu .nav-bar ul li span {
    font-size: 13px;
  }

  header .header-content .header-menu .sign-btns ul li a {
    min-width: 110px;
  }

  header .header-content .header-menu .sign-btns ul li.log button {
    min-width: 70px;
  }

  .homepage section.main-section .main-content .image-block {
    display: none;
  }

  .homepage section.main-section {
    align-items: center;
  }

  .analytics-content {
    display: block;

    .analytics-item {
      max-width: 100%;
      text-align: center;
    }
  }

  .analytics-content .analytics-item:first-child {
    border: none;
  }

  .item-chart {
    width: 525px;
    margin: 0 auto;
  }

  .messages .plus-icon {
    width: 728px;
  }

  .homepage section.mac-section .mac-images .iphone-c-img {
    width: 225px;
  }

  .homepage section.mac-section .mac-images .mac-img {
    width: 430px;
  }

  section.blue-section .blue-content .blue-text {
    flex: 0 0 100%;
    max-width: 100%;
  }

  section.blue-section .blue-content .blue-img {
    flex: 0 0 25%;
    max-width: 25%;
  }

  footer .footer-content .footer-menu ul li {
    padding: 0 15px;
  }

  header .header-content .header-logo a img {
    width: 30px;
  }

  header .header-content .header-logo a .logo-title h1 {
    font-size: 16px;
  }

  header .header-content .header-menu .nav-bar ul li span,
  header .header-content .header-menu .nav-bar ul li a {
    font-size: 11px;
  }

  header .header-content .header-menu .sign-btns ul li.log button {
    min-width: 60px;
  }

  // .dashboard .address .info_address ul {
  //   flex-wrap: wrap;
  // }

  .container .lessons .lessons-list .lesson-item {
    display: block;
    margin-right: 15px;
  }
}

//**************************************************************************************************************************
@media #{$lg} {
  .container {
    width: 962px !important;
  }

  .main-container {
    width: 962px;
  }

  header .header-content .header-menu .nav-bar ul li {
    padding: 0 5px;
  }

  header .header-content .header-menu .sign-btns h4 {
    margin-left: 5px;
    margin-right: 5px;
  }

  header .header-content .header-menu .sign-btns ul li {
    padding: 0 5px;
  }

  header .header-content .header-menu .nav-bar ul li a,
  header .header-content .header-menu .nav-bar ul li span {
    font-size: 13px;
  }

  .homepage section.main-section .main-content .text-block .main-title h2 {
    font-size: 21px;
  }

  .analytics-content {
    display: block;

    .analytics-item {
      max-width: 100%;
      text-align: center;
    }
  }

  .analytics-content .analytics-item:first-child {
    border: none;
  }

  .item-chart {
    width: 525px;
    margin: 0 auto;
  }

  .messages .plus-icon {
    width: 965px;

    &.bottom {
      margin-left: 0;
    }
  }

  .messages .messages-list {
    width: 910px;
    margin: 0 auto;
  }

  .homepage section.mac-section .mac-images .mac-img {
    width: 620px;
  }
}

@media (max-width: 1024px) {
  .dashboard .address .info_address ul {
    display: block;

    li {
      width: 100%;
      justify-content: center;

      padding: 5px;
      border-top: 1px solid #000;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .dashboard .address .info_address ul li {
    font-size: 14px;
  }
}

@media (min-width: 1900px) {
  .dashboard .table-content .table-container {
    flex-basis: calc(100% - 350px);
  }
}
