section {
  &.main-section {
    background-size: cover;
    background-position: 100% 55%;
    min-height: 500px;
    display: flex;

    .main-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      overflow: hidden;
      margin-top: 80px;

      .text-block {
        .message-section {
          font-size: 19px;
          font-style: italic;
          color: white;
          margin-bottom: 60px;

          p {
            margin-bottom: 20px;
          }
        }

        .main-title {
          padding-bottom: 100px;
          text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.7);



          h2 {
            font-size: 36px;
            letter-spacing: 0.6px;
            color: #ffffff;
            font-weight: normal;
            margin-right: -100px;

            span {
              font-weight: bold;
            }
          }
        }
      }

      .image-block {
        display: flex;
        align-items: flex-end;

        img {
          height: 100%;
        }

        .iphone-a,
        .iphone-b {
          font-size: 0;
          width: 361px;
          padding: 0 20px;

          img {
            width: 100%;
          }
        }

        .iphone-a {
          transform: translateX(175px);
          position: relative;
          z-index: 1;
        }
      }
    }
  }

  &.mac-section {
    padding-bottom: 80px;

    img.img {
      width: 100%;
      height: auto;

    }

    .mac-images {
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      margin-bottom: -50px;
      margin-top: -250px;

      .create-account {
        padding-bottom: 25px;

        ul {
          display: flex;
          justify-content: center;
          align-items: center;

          li {
            list-style: none;
            font-size: 14px;
            color: #313234;
            font-weight: bold;
            margin-right: 10px;
            margin-left: 20px;
            display: flex;
            align-items: center;

            img {
              margin-left: 5px;
              width: 16px;
            }

            a {
              text-decoration: none;
              min-width: 150px;
              color: #ffffff;
              background-color: #9ad308;
              padding: 5px 0;
              font-size: 14px;
              border-radius: 3px;
              display: block;
              text-align: center;
            }
          }
        }
      }

      .demo-btn {
        text-align: center;
        transform: translateY(-55px);

        button {
          font-size: 14px;
          color: #313234;
          font-weight: 700;
          padding: 3px 10px;
          background-color: transparent;
          border: 1px solid #9ad308;
          border-radius: 3px;
          cursor: pointer;
        }
      }

      .iphone-c-img {
        width: 300px;
        padding: 25px;

        img {
          width: 100%;
        }

        .download-app {
          justify-content: flex-start;
          margin: 10px 0;

          h3,
          p {
            color: #000000;
          }

          ul li img {
            width: 120px;
          }
        }
      }

      .mac-img {
        width: 640px;
        padding-top: 75px;

        img {
          width: 100%;
        }
      }
    }

    .mac-bottom {
      padding-top: 15px;

      .mac-logo {
        padding-bottom: 10px;
      }

      .mac-text {
        display: flex;
        padding-bottom: 20px;

        .iphone-c-img,
        .mac-img {
          display: none;

          .create-account {
            padding-bottom: 25px;

            ul {
              display: flex;
              justify-content: center;
              align-items: center;

              li {
                list-style: none;
                font-size: 14px;
                color: #313234;
                font-weight: bold;
                margin-right: 10px;
                margin-left: 20px;
                display: flex;
                align-items: center;

                img {
                  margin-left: 5px;
                  width: 16px;
                }

                a {
                  text-decoration: none;
                  min-width: 150px;
                  color: #ffffff;
                  background-color: #9ad308;
                  padding: 5px 0;
                  font-size: 14px;
                  border-radius: 3px;
                  display: block;
                  text-align: center;
                }
              }
            }
          }

          .demo-btn {
            text-align: center;
            transform: translateY(-55px);

            button {
              font-size: 14px;
              color: #313234;
              font-weight: 700;
              padding: 3px 10px;
              background-color: transparent;
              border: 1px solid #9ad308;
              border-radius: 3px;
              border: none;
              cursor: pointer;
            }
          }
        }

        .col {
          flex: 0 0 50%;
          max-width: 50%;
          padding-right: 30px;

          h3 {
            font-size: 22px;
            font-weight: bold;
            color: #313234;
            padding-bottom: 15px;
          }

          p {
            font-size: 18px;
            color: #313234;
          }
        }
      }

      .download-app {
        margin-bottom: 15px;

        ul {
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;

          li {
            list-style: none;
            padding-right: 25px;

            img {
              border-radius: 15px;
              max-width: 68px;
            }

            a {
              img {
                max-width: 135px;
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }

  &.blue-section {
    background-color: #09b1e7;
    padding: 30px 0;

    .blue-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .blue-text {
        flex: 0 0 20%;
        max-width: 20%;

        h3 {
          font-size: 22px;
          font-weight: bold;
          color: #ffffff;
          padding-bottom: 10px;
        }

        p {
          font-size: 18px;
          color: #ffffff;
        }
      }

      .blue-img {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 10px;

        img {
          width: 100%;
        }
      }
    }
  }

  &.section-d {
    img.img {
      width: 100%;
      height: auto;

    }

    .section-d-content {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      margin-top: -180px;

      .section-d-img {
        flex: 0 0 35%;
        font-size: 0;
        padding: 0 30px 30px;
        max-width: 300px;

        img {
          width: 100%;
        }

        .download-app {
          justify-content: flex-start;
          margin-top: 10px;

          h3 {
            color: #000000;
          }

          ul li img {
            width: 120px;
          }
        }
      }

      .mac-img {
        width: 640px;
        padding-top: 75px;

        img {
          width: 100%;
        }
      }

      .section-d-text {
        flex: 0 0 40%;
        max-width: 40%;
        padding-bottom: 70px;

        h3 {
          font-size: 22px;
          font-weight: bold;
          color: #313234;
          padding-bottom: 10px;
        }

        p {
          font-size: 18px;
          color: #313234;
        }
      }
    }

    .download-app .download-logo p {
      color: #000000;
    }
  }
}

.site-url {
  text-align: center;

  a {
    color: #000000;
    font-style: italic;
  }
}

.pricelist {
  margin: 20px 0;
  overflow-x: auto;

  table {
    width: 100%;
    min-width: 1170px;

    tr {
      th {
        color: rgb(254, 21, 18);
        white-space: nowrap;
      }

      td {
        >img {
          width: 20px;
        }

        >button {
          display: inline-block;
          min-width: 108px;
          padding: 5px 0;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
          background-color: #9ad308;
          border-radius: 4px;
          cursor: pointer;
        }
      }

      th,
      td {
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 8px;

        &.center {
          text-align: center;
        }

        &.note {
          position: relative;
          padding-right: 40px;
        }

        &.center {
          &.note {
            padding: 8px;
          }
        }

        &.bn {
          border: none;
        }

        &.pr-account,
        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          background-color: rgb(222, 234, 246);
        }

        &.cm-account,
        &:nth-child(5) {
          background-color: rgb(226, 238, 217);
        }

        &.ep-account,
        &:nth-child(7) {
          background-color: rgb(255, 217, 102);
        }

        &.price {
          background-color: rgb(254, 255, 0);
        }
      }
    }
  }
}

.download-app {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 10px;

  .download-logo {
    width: 240px;
    display: flex;
    align-items: center;
    padding-bottom: 15px;

    img {
      width: 72px;
      min-width: 72px;
      border-radius: 15px;
    }

    p {
      font-size: 14px;
      color: #ffffff;
      padding-left: 5px;
    }
  }

  h3 {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.43px;
    color: #ffffff;
    padding-bottom: 5px;
  }

  ul {
    display: flex;

    li {
      list-style: none;
      padding-bottom: 5px;

      img {
        width: 120px;
      }
    }
  }
}


.demo-btn {
  border: 2px solid #9ad3087a;
  color: #00c6ff;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  font-size: 16px;
  right: 0;
  max-width: 185px;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
}
