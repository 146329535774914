.athlets-slider {
  .slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 66px;
    padding-bottom: 20px;

    .each-img {
      flex: 0 0 23%;
    }
  }

  .titles {
    position: relative;
    width: 735px;
  }

  img {
    max-width: 100%;
  }

  .small-circle {
    width: 51px;
    position: absolute;
    left: 700px;
    top: 115px;
  }

  .middle-circle {
    width: 97px;
    position: absolute;
    left: 780px;
  }

  .main-container {
    padding-top: 50px;
    position: relative;
    padding-left: 150px;
    padding-right: 150px;
  }

  .title {
    font-family: Helvetica;
    font-size: 30px;
    color: #292A34;
    letter-spacing: 0.89px;
    line-height: 35px;
    margin-top: 19px;
  }

  position: relative;
  background-image: linear-gradient(223deg, rgba(31, 144, 20, 0.20) 0%, rgba(89, 185, 0, 0.10) 100%);

  .sub-title {
    font-family: Helvetica;
    font-size: 16px;
    color: #292A34;
    letter-spacing: 0.29px;
    line-height: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;

    .line {
      border: 1px solid #292A34;
      width: 28px;
      height: 1px;
      display: inline-block;
      margin-right: 15px;
    }
  }
}

@media (max-width: 768px) {
  .athlets-slider .title {
    line-height: 1;
    text-align: center;
  }

  .athlets-slider {
    .main-container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .athlets-slider .titles {
    width: 100%;
  }

  .small-circle,
  .middle-circle {
    display: none;
  }

  .athlets-slider .slider {
    flex-direction: column;
  }

  .athlets-slider .slider .each-img {
    flex: 0 0 100%;
    margin-bottom: 20px;
    display: none;
    ;
  }

  .athlets-slider .slider .each-img:first-child {
    flex: 0 0 100%;
    margin-bottom: 20px;
    display: block !important;
  }
}
