.analytics {
  h2 {
    text-align: center;
    padding: 30px 10px 0 10px;
  }

  .title-h2 {
    font-size: 22px;
    color: #1bb1e4;
  }
}

.analytics-content {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;

  .analytics-item {
    flex: 0 0 33%;
    max-width: 33%;
    padding-left: 25px;
    padding-right: 15px;

    &:first-child {
      border-right: 1px solid #bbbbbb;
      padding-left: 15px;
      padding-right: 25px;
    }

    &:last-child {
      border-left: 1px solid #bbbbbb;
    }

    .item-title {
      text-align: center;
      padding: 15px 0;

      h2 {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .item-text {
      ul {
        li {
          list-style: none;
          padding: 10px 0;

          &:first-child {
            color: #ff6439;
            border-bottom: 1px solid #bbbbbb;
          }
        }
      }
    }

    .chart-graph {
      margin-bottom: 25px;
    }
  }
}
