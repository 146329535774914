.demo-button {

  button {
    background: #9AD308;
    border-radius: 20px;
    font-family: Helvetica;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0.36px;
    text-align: center;
    min-width: 140px;
    border: 1px solid #9ad308;
    padding: 5px 30px;
    cursor: pointer;
    outline: none;
    height: 37px;
    font-weight: 500;
  }

}
