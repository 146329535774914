.image_upload {
  text-align: center;
}

.bgw {
  background-color: unset;
}

.check-group.profile-access {
  justify-content: flex-start;
  flex-wrap: unset;
  margin-top: 15px;

  &:before {
    top: 0;
  }

  .radio-label h4 {
    margin-top: 0;
  }
}

.change-password {
  padding: 10px 0;

  button {
    display: inline-block;
    padding: 6px 0;
    font-size: 18px;
    color: #ffffff;
    background-color: #9ad308;
    border: none;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    min-width: 200px;
  }
}

#submit-button {
  display: block;
  padding: 8px 0;
  font-size: 18px;
  color: #ffffff;
  background-color: #9ad308;
  border: none;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  width: 212px;
  margin: 10px auto;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  line-height: 1;
  height: 35px;

  &:disabled {
    background-color: #4c4e49;
  }

  &.withpromo {
    display: inline;
    margin: 0 5px;
  }
}

.payment-block {
  border-top: 1px solid #9ca18d;
  padding-top: 10px;
}

.check-promo {
  cursor: pointer;
  font-size: 18px;
  padding: 8px;
  background-color: #9ad308;
  border: 1px solid #9ad308;
}

.unsubscribe {
  background: #fafafa;
  border-radius: 4px;
  color: #000;
  margin: 0 auto;
  padding: 18px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;

  &:hover {
    background: #fcfcfc;
  }

  button {
    border: none;
    border-bottom: 1px solid #b5b5b5;
    padding-bottom: 1px;
    font-size: 14px;
    background-color: transparent;
    cursor: pointer;
    font-weight: 300;
    color: #b5b5b5;

    &:hover {
      border-color: #000;
      font-weight: 500;
      color: #000;
    }
  }
}

.coachList {
  width: 100%;

  td,
  th {
    padding: 5px;
    border-bottom: 1px solid #ddd;
  }
}

.upgrade-container {
  tbody tr {

    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.upgrade-container-ace {
  tbody tr {

    td:nth-child(3),
    td:nth-child(4) {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.court_provider {
  tbody tr {
    td:nth-child(3) {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  thead tr {
    th:nth-child(3) {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.lesson-selected {
  tbody tr {
    td:nth-child(4) {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  thead tr {
    th:nth-child(4) {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
