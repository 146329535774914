.sign-up-bg {
  background-color: #ffffff;
}

.signUp-from {
  width: 600px;
  margin: 25px auto 0;

  .form-group {
    display: flex;
    align-items: center;

    label {

      &.label-text,
      &.onoff-text {
        flex: 0 0 45%;
        cursor: pointer;
      }

      &.onoff-text {
        p {
          font-weight: bold;
        }
      }
    }

    input {
      width: auto;
      flex: 0 0 55%;
    }
  }

  .textarea-group {
    align-items: flex-start;
    position: relative;

    &.lesson {
      &:before {
        content: unset;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: -25px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #9ca18d;
    }

    .notification {
      span {
        &.not-icon {
          top: 10px;
          bottom: auto;
        }
      }
    }
  }

  h3 {
    margin-top: 12px;
    font-size: 18px;
    padding-top: 12px;
    border-top: 1px solid #9ca18d;
  }

  h4 {
    margin-top: 18px;
    font-size: 16px;
    padding-top: 18px;
    border-top: 1px solid #9ca18d;
  }

  .b0 {
    border: none;
  }

  .radio-group {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .radio-label {
      flex: 0 0 45%;

      h4 {
        border: none;
        margin-top: 0;
      }
    }

    .select-country {
      flex: 0 0 55%;

      select {
        border: 1px solid #606948;
        background-color: transparent;
        font-size: 16px;
        outline: none;
        padding: 5px;
        border-radius: 3px;
        flex: 0 0 55%;
        width: 100%;

        &.error {
          border-color: red;
        }
      }
    }

    .radio-form {
      flex: 0 0 15%;

      .form-group {
        justify-content: flex-end;
        margin-top: 3px;

        input {
          visibility: hidden;
          position: absolute;
        }

        label {
          width: 80px;
          text-align: center;
          border: none;
          border-radius: 0;
          background-color: #dbdbdb;
          margin-right: 0;
          display: block;
          padding: 8px 0;
          height: auto;
          text-transform: uppercase;

          &:after {
            display: none;
          }
        }

        input[type=radio] {
          &:checked~label {
            &.label-icon {
              background-color: #aaaaaa;
            }
          }
        }
      }
    }
  }
}

.have-account {
  padding-top: 15px;
  text-align: center;

  p {
    font-size: 18px;
    color: #ffffff;
    padding-bottom: 10px;

    a {
      text-decoration: none;
      color: #ffffff;
      font-weight: bold;
      font-size: 18px;
    }
  }
}

.sign-up-btn {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 30px;

  button {
    display: inline-block;
    padding: 6px 0;
    font-size: 18px;
    color: #ffffff;
    background-color: #9ad308;
    border: none;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    min-width: 90px;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    min-width: 90px;
    display: inline-block;
    padding: 4px 0;
    font-size: 18px;
    color: #ffffff;
    background-color: #9ad308;
    padding-left: 10px;
    padding-right: 10px;
  }

  ul {
    display: flex;
    justify-content: center;

    li {
      list-style: none;
      padding: 0 5px;
    }
  }
}
.sign-up-btn-new-center  {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 30px;
  max-width: 25%;
  margin: auto;
}
.sign-up-btn-new  {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 30px;
  max-width: 25%;
  

  a {
    text-decoration: none;
    font-weight: bold;
    min-width: 80px;
    display: inline-block;
    padding: 8px 0px 8px 25px;
    color: #ffffff;
    position: relative;
    
    i{
      position: absolute;
      left: 8%;
      transition: all .6s;
    }
  }

  ul {
    display: flex;
    justify-content: center;

    li {
      list-style: none;
      padding: 0 5px;
    }
  }
}

.edit-form .form-group input {
  font-size: 16px;
}

.price-wrapp {
  position: relative;

  .form-group {
    input {
      flex: 0 0 33%;
      transition: all 1.5s;
      overflow: hidden;

    }

  }

  .notification span.not-icon {
    right: 1px;
  }

  .priceButton {
    flex: 0 0 22%;
    transition: all 1.5s;
    position: relative;
    padding-left: 10px;

    button {
      background-color: #9ad308;
      color: #ffffff;
      height: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
    }
  }

  &.active {
    .form-group {
      justify-content: flex-start;

      input {
        flex: 0 0 2%;
        display: flex;
        overflow: hidden;
        transition: all 1.5s;
      }
    }

    .priceButton {
      flex: 0 0 53%;

      button {
        text-align: right;
        overflow: hidden;
      }
    }
  }
}

.on-off {
  .form-group {
    label {
      order: unset;

      &.label-text {
        font-weight: bold;
      }

      &.label-icon {
        width: 44px;
        height: 24px;
        display: inline-block;
        background-color: #ffffff;
        border-radius: 12px;
        border: none;
        box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.8);

        &:after {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          left: 22px;
          top: 2px;
          background-image: none;
          background-color: #9ad308;
          opacity: 1;
          transition: all .2s linear;

        }
      }
    }

    input {
      display: none;

      &:checked~label {
        &:after {
          left: 2px;
        }
      }
    }
  }
}

.dynamic-form {
  .login_info {
    border-top: 0;
  }
}
