.schedule-demo-container {
  width: 820px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 11px 35px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding-bottom: 24px;

  .title {
    h2 {
      font-family: Helvetica;
      font-size: 25px;
      color: #292a34;
      letter-spacing: 0.45px;
      text-align: center;
      padding-bottom: 33px;
      padding-top: 34px;
    }
  }
}

.facility_groups-select {
  flex: 0 0 100% !important;
  max-width: 100% !important;

  .picky {
    max-width: 100% !important;
  }
}

.modal-content .auth-container {
  .facility_groups-select {
    flex: 0 0 65% !important;
    max-width: 65% !important;
  }
}

.schedule-demo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 105px;
  padding-right: 105px;

  .form-group-rounded {
    flex: 0 0 45%;
    margin-bottom: 15px;
  }

  .large-input {
    flex: 0 0 100%;
    margin-bottom: 15px;
  }

  .signin {
    flex: 0 0 45%;
    margin-bottom: 15px;
    padding-bottom: 0 !important;
    margin: auto;

    button {
      text-transform: uppercase;
      background: #9ad308;
      box-shadow: 0 15px 25px 0 rgba(0, 119, 255, 0.15);
      border-radius: 24px !important;
      font-family: Helvetica;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0.25px;
      text-align: center;
      width: 100%;
      border: none;
      padding: 12px;
      height: 41px !important;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .schedule-demo-container {
    width: 100%;

    .schedule-demo {
      padding-left: 15px;
      padding-right: 15px;

      .form-group-rounded {
        flex: 0 0 100%;
      }

      .signin {
        margin: 0 auto;
      }
    }
  }
}
