.dropdown_menu {
  position: relative;
  margin-right: 2%;


  a {
    padding-left: 5%;
    display: block;
    z-index: 3;
  }

  li {
    border-top: .5px solid lightgray;
    padding: 4% !important;
  }

  li:first-child {
    border-top: none;
  }

  ul {
    transition: all .5s;
    width: 230px;
    margin: auto;
  }

  .cursor {
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transition: all .5s;
  }
}

.dropdown_menu:hover {
  .cursor {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
}

header .header-content .header-menu .nav-bar ul li:hover ul {
  transition: all .5s;
}
@media (max-width:1024px){
  .dropdown_menu {
    .cursor {
      right: -5px;
   
     
    }

   
  }
}
@media (max-width:768px) {
  .dropdown_menu {
    .cursor {
      right: 30%;
      top: 5%;
      padding-left: 2%;
    }

    ul {
      display: block !important;
    }
  }

  .dropdown_menu:hover {
    .cursor {
      top: 5%;
    }
  }
}
