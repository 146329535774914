.micro-settings {

  .date-picker,
  .table-item {
    max-width: 100px;
  }

  .table {
    tr {

      th,
      td {
        text-align: center;
      }
    }
  }
}

.d-flex {
  display: flex;
}

.freeze-list-container {
  .table-responsive {
    position: relative;
    min-height: 250px;
  }
}

.checkIfCancelTrue {
  width: 10%;
  text-align: center;
  margin: auto;
  margin-bottom: 3%;
  margin-top: -3%;
  display: none;

  input {
    width: 125%;
    padding: 3%;
    border: none;
    border: 2px solid grey;
  }

  span {
    font-size: 24px;
    font-family: fantasy;
    font-weight: bold;
    font-style: italic;
    width: 100%;
    text-shadow: 0 0 6px black;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }
}

.freeze-container {
  .cancell_bookings_lable {
    font-size: 16px;
    margin-right: 3%;
  }

  .main_cancell_bookings_lable {
    width: 100% !important;
    margin: auto;
    display: block;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 32px;
  }

  .typeform-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;

    input[type='text'],
    input[type='password'],
    input[type='email'],
    textarea,
    input[type='number'] {
      width: 100%;
      height: 31px;

      padding-left: 5px;

      &.error {
        border-color: red;
      }
    }

    textarea {
      width: 100%;
      resize: none;
      height: 120px;

      &.error {
        border-color: red;
      }
    }

    select {
      flex: 0 0 55%;
      height: 31px;
    }

    label {
      &.label-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        order: 1;
      }
    }
  }
}
