.who-can-book {
  text-align: left;

  input[type="checkbox"] {
    position: static !important;
    visibility: visible !important;
    width: auto !important;
  }

  .select__menu {
    z-index: 2 !important;
  }

  label {
    display: inline-block !important;
    padding-left: 10px;
    font-weight: 400 !important;
    font-size: 14px !important;

  }
}
