.auth {
  background-color: #9ad308;
  flex: 1;

  &.p0 {
    padding: 0;
  }

  .auth-logo {
    width: 130px;
    margin: 0 auto;
    padding: 10px 0;

    img {
      width: 100%;
    }
  }

  .auth-title {
    text-align: center;

    h1 {
      font-size: 30px;
      color: #ffffff;
      padding-bottom: 5px;
    }

    h3 {
      font-size: 25px;
      color: #ffffff;

      span {
        font-size: 26px;
        font-weight: bold;
      }
    }
  }

  .auth-form {
    width: 400px;
    margin: 0 auto;

    .form-group {
      input {
        border: none;
        border-bottom: 1px solid #606948;
        padding: 5px 0;

        &.error {
          border-color: red;
        }
      }
    }

    .forgot-password {
      text-align: right;
      padding-top: 5px;
      display: flex;
      justify-content: space-between;

      a {
        font-size: 12px;
        color: #606948;
        text-decoration: none;
      }

      p {
        font-size: 12px;
        color: #ff0000;
      }
    }

    .signup {
      text-align: right;
      padding-top: 50px;

      a {
        text-decoration: none;
        font-size: 18px;
        color: #ffffff;
      }
    }

    .signin {
      text-align: center;
      padding-top: 10px;

      button,
      a {
        font-size: 20px;
        color: #ffffff;
        font-weight: bold;
        cursor: pointer;
      }

      button {
        outline: none;
        border: none;
        background-color: transparent;
      }

      a {
        text-decoration: none;
      }
    }

    .forgot {
      ul {
        text-align: right;

        li {
          display: inline-block;
          padding: 0 5px;

          a {
            text-decoration: none;
            font-size: 20px;
            color: #ffffff;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }

    .usernames {
      h3 {
        padding: 10px 0;
        color: #ffffff;
      }

      ul {
        li {
          list-style: none;
          color: #606948;
          cursor: pointer;
          padding: 3px;
          margin: 10px 0;
          border: 1px solid #ffffff;
          border-radius: 3px;
        }
      }
    }
  }
}
