.request-modal {
  .request-modal-demo {
    .form-group-rounded {
      flex: 0 0 100%;
    }
  }

  .modal-content {

    .auth-container {


      .schedule-demo {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

}

@media (max-width: 768px) {
  .modal-content {
    max-width: 100% !important;
    width: 100% !important;

    .auth-container {
      width: 100% !important;

      .schedule-demo {
        padding-left: 15px;
        padding-right: 15px;
        flex-direction: column;
        align-items: inherit;
      }
    }

    .schedule-demo .form-group-rounded {
      flex: 0 0 100%;
      margin-bottom: 15px;
    }
  }
}
