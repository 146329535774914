.chargeBuuton {
  display: inline-block;
  padding: 6px 6px;
  font-size: 18px;
  color: #ffffff;
  background-color: #9ad308;
  border: none;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  min-width: 90px;
}
