.messages {
  padding: 25px 0;


  .messages-list {
    position: relative;
    min-height: 200px;


    ul {
      li {
        list-style: none;


        .no-padding {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        .m-top-10 {
          margin-top: 10px;
        }

        .message-block {
          display: flex;
          align-items: center;

          .message-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 0 0 100%;

            .message-avatar {
              display: flex;
              flex: 0 0 50%;
              align-items: center;
              flex-wrap: wrap;



              .avatar-name {
                flex: 0 0 calc(100% - 60px);
                max-width: calc(100% - 60px);
                padding-left: 10px;
                padding-right: 10px;

                ul {
                  li {
                    position: relative;

                    font-size: 14px;
                    padding: 2px 4px;

                    &:last-child {
                      display: inline-block;
                    }

                    border: none;

                    span {
                      font-weight: bold;
                    }

                    .notification {
                      vertical-align: middle;
                      display: inline-block;

                      .not-icon {
                        position: unset;
                        margin-left: 3px;
                        padding: 0;
                      }

                      p {
                        margin-bottom: 15px;
                        margin-left: -25px;
                      }
                    }

                    &:last-child {
                      display: flex;
                      align-items: center;

                      span {
                        padding-right: 3px;
                        white-space: nowrap;
                      }
                    }
                  }
                }
              }

              .court-name {
                flex: 0 0 100%;
                max-width: 100%;
                padding-top: 10px;
              }
            }

            .message-cennter {
              flex: 0 0 27%;

              ul {
                li {
                  font-size: 14px;
                  padding: 2px 0;
                  border: none;

                  span {
                    font-weight: bold;
                  }
                }
              }
            }

            .popup-buttons {
              flex: 0 0 27%;

              ul {
                li {
                  font-size: 14px;
                  padding: 2px 0;
                  border: none;

                  span {
                    font-weight: bold;
                  }
                }
              }
            }

            .message-right {
              ul {
                text-align: center;

                li {
                  display: block;
                  font-size: 14px;
                  font-weight: bold;
                  border: none;
                  padding: 0;

                  span {
                    text-align: center;
                    outline: none;
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: bold;
                    color: orange;
                    border: none;
                    min-width: 120px;
                  }

                  &:first-child {
                    color: #50d4f1;
                    padding-bottom: 5px;
                  }

                  &:last-child {
                    color: #c9c9c9;
                  }
                }
              }

              &.not {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-self: stretch;

                h5 {

                  text-align: left;
                  font-weight: normal;
                  font-size: 15px;
                }
              }
            }
          }

          .message-arrow {
            flex: 0 0 3%;
            max-width: 3%;

            button {
              cursor: pointer;
              background-color: transparent;
              border: none;
              outline: none;
            }

            img {
              max-width: 16px;
            }

            &.active {
              img {
                transform: rotate(90deg);
              }
            }
          }
        }

        .message-text {
          width: 97%;

          p {
            font-size: 15px;
            color: #3a3a3a;
            width: 100%;
          }
        }
      }
    }
  }

  .plus-icon {
    position: static;
    right: 0;
    left: 0;
    bottom: 10px;

    button,
    a {
      color: #ffffff;
      background-color: #9ad308;
      padding-bottom: 5px;
      padding-top: 5px;
      padding-left: 5px;
      padding-right: 5px;
      width: 30px;
      height: 30px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      font-weight: bold;
      cursor: pointer;
      border: none;
      border-radius: 50%;
      margin-left: auto;
      line-height: 0.8;

      img {
        max-width: 20px;
      }
    }
  }
}

.csv-file {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  input {
    display: none;
  }

  label {
    display: inline-block;
    margin-bottom: 10px;
    min-width: 110px;
    flex: 0 0 12%;
    max-width: 12%;
    border: 1px solid #9ad308;
    background-color: transparent;
    color: #313234;
    padding: 5px 0;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
  }

  p {
    flex: 0 0 80%;
    max-width: 80%;
    padding-left: 10px;
    padding-top: 3px;
  }

  a {
    display: inline-block;
    text-align: right;
    text-decoration: none;
    color: #50d4f3;
    font-weight: bold;
  }

  button {
    min-width: 110px;
    flex: 0 0 12%;
    max-width: 12%;
    border: 1px solid #9ad308;
    background-color: transparent;
    color: #313234;
    padding: 5px 0;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    text-align: center;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
}
