.filter-availability-list {
  margin-top: 20px;
  position: relative;
  .not-icon{
    left: 0% !important;
    top: -38px !important;
    margin-top: 40px !important;
  }
  .rec-form .form-group:first-child {
    padding-right: 0;
  }
}

.modal-availability {
  .sign-up-btn {
    padding-top: 0;
    padding-bottom: 0;
    button {
      height: 33px;
    }
  }
}
