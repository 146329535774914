@charset "UTF-8";

/* helpers */
@import "helpers/helpers";

@import "~croppie/croppie.css";

.app-bootstrap-support {
  @import "custom/bootstrap";
}

/* Main Styles */
* {
  box-sizing: border-box;
}

html,
body,
#app {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-size: $font-base;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

}

a {
  outline: 0 !important;
}

p {
  cursor: default;
}

.react-datepicker-wrapper {
  width: 100%;
}

.active {
  .modal-content {
    position: relative;
  }

}

.pos_relative {
  position: relative;
}

.checkIfCancelTrueLogin {
  width: 25%;
  text-align: center;
  margin: auto;
  margin-bottom: 3%;
  margin-top: -3%;


  input {
    width: 100%;
    padding: 3%;
    border: none;
    border: 2px solid grey;
  }

  span {
    font-size: 24px;
    font-family: fantasy;
    font-weight: bold;
    font-style: italic;
    width: 100%;
    text-shadow: 0 0 6px black;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }
}

.over_modal {
  width: 96%;
  height: 83%;
  position: absolute;
  z-index: 1;
  background-color: #ffffff59;
}

.over_modal_booking {
  width: 96%;
  height: 77%;
  position: absolute;
  z-index: 1;
  background-color: #ffffff59;
  top: 20%;
}

.cancel_allowed_hour {
  text-align: center;
  border: .5px solid;
}

@media print {

  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

}

/* Fonts */

/* end Main Styles */
@import "custom/custom";
@import "custom/datapicker";
@import "custom/react-tabs";
@import "custom/picky";
/* header */
@import "partials/header";
/* layouts */
@import "pages/homepage";
@import "pages/modal";
@import "pages/auth";
@import "pages/dashboard";
@import "pages/signUp";
@import "pages/profile";
@import "pages/analytics";
@import "pages/paymodal";
@import "pages/messages";
@import "pages/pricing";
@import "pages/stripeconnect";
@import "pages/lessons";
@import "pages/group";
@import "pages/tables";
/* footer */

@import "partials/footer";
@import "custom/responsive";

////////////////////////////////////////////////////////////////* RETINA MEDIA*/

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 767px),
only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 767px),
only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 767px),
only screen and (min-device-pixel-ratio: 2) and (max-width: 767px),
only screen and (min-resolution: 192dpi) and (max-width: 767px),
only screen and (min-resolution: 2dppx) and (max-width: 767px) {}
