.overPage{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    background: black;
    position: absolute;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #4c4c4c78;
  }
  
  :root {
    --rotation-animation-speed: 2s;
     --rotation-animation-easing: linear;
     --stroke-animation-speed: 1.5s;
     --stroke-animation-easing: ease-in-out;
     --stroke-width: 4px;
     --stroke-start-dasharray: 1, 200;
     --stroke-end-dasharray: 89, 200;
    
 }
 
 .loaderMain {
     margin: 0px auto;
     max-width: 70px;
     min-width: 60px;
     height: auto;
 }
 
 .circular-loader {
     animation: rotate var(--rotation-animation-speed) var(--rotation-animation-easing) infinite;
 }
 
 .loader-path {
     fill: none;
     stroke-width: var(--stroke-width); 
     animation: animate-stroke var(--stroke-animation-speed) var(--stroke-animation-easing) infinite;
     stroke-linecap: round;
 }
 
 @keyframes rotate {
     100% {
         transform: rotate(360deg);
     }
 }
 
 @keyframes animate-stroke {
     0% {
         stroke-dasharray: var(--stroke-start-dasharray);
         stroke-dashoffset: 0;
         stroke: #9ad308;
     }
     50% {
         stroke-dasharray: var(--stroke-end-dasharray);
         stroke-dashoffset: -35;
         stroke: #9ad308;
     }
     100% {
         stroke-dasharray: var(--stroke-end-dasharray);
         stroke-dashoffset: -124;
         stroke: #9ad308;
     }
 }