.receipt-modal {
  .modal_bg {
    height: 100px;
    background-repeat: no-repeat;
    background-size: 99%;
    background-position: -3%;
    width: 110%;
    margin-left: -20px;
    margin-top: -13px;
  }

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }

  .modal-content {
    overflow: hidden;

    .table {
      .receiptLink {
        color: rgb(154, 211, 8) !important;
      }

      .ordersTable {
        * {
          font-size: 10px !important;
        }

        th {
          width: 24%;
        }
      }
    }
  }

  .table td:nth-child(2) {
    padding-left: 10%;
  }



  &--data-list {
    display: block;
    font-size: 16px;
    font-weight: normal;
    padding: 0 20px;
    overflow: hidden;





    li {
      display: flex;
      justify-content: space-between;

      span {
        font-weight: bold;
      }

      a {
        color: rgb(154, 211, 8);
      }
    }
  }

  @media (max-width: 768px) {
    .modal_bg {
      margin-top: -26px;
    }
  }
}
