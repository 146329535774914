// variables
$green: #9ad308;
$black: #292a34;
$gray-600: #9b9b9b;
$input-btn-padding-y: 0.15rem;
$input-btn-padding-x: 0.4rem;
$input-btn-focus-box-shadow: none;
$border-radius: 0.5rem;
$input-color: #9b9b9b;
$input-border-color: lighten(#9b9b9b, 25%);
$input-focus-border-color: $input-border-color;
$btn-focus-width: 0;

$custom-checkbox-indicator-border-radius: 2px;
$custom-control-indicator-size: 0.9rem;
$custom-control-cursor: pointer;
$component-active-bg: $green;
// $input-border-radius:

// imports
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// cleanup bootstrap
$theme-colors: map-remove($theme-colors, 'info', 'light');

// import component codes

// @import '~bootstrap/scss/root';
// @import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
// @import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
// @import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
// @import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
// @import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
// @import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
// @import '~bootstrap/scss/progress';
// @import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
// @import '~bootstrap/scss/close';
// @import '~bootstrap/scss/toasts';
// @import '~bootstrap/scss/modal';
// @import '~bootstrap/scss/tooltip';
// @import '~bootstrap/scss/popover';
// @import '~bootstrap/scss/carousel';
// @import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
// @import '~bootstrap/scss/print';

// customize bootstrap

.btn-success {
  color: #fff;
}

.btn-outline-secondary {
  border-color: lighten(#9b9b9b, 25%);

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    border-color: lighten(#9b9b9b, 25%);
    background-color: lighten(#9b9b9b, 25%);
  }
}

.show > .btn-outline-secondary.dropdown-toggle {
  border-color: lighten(#9b9b9b, 25%);
  background-color: lighten(#9b9b9b, 25%);
}

a {
  color: $black;
}

.custom-control-label {
  user-select: none;

  &::before {
    top: 0rem;
    // left: -1.1rem;
  }
  &::after {
    top: 0.05rem;
    left: -1.35rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.picky_container:not(.kep-original-style) .picky__input {
  width: 100%;

  // background-color: white;
  // border: 0;
  // border-width: 1px;
  // border-color: #eee;
  // border-style: solid;
  // border-radius: 3px;

  // padding: 5px;
  text-align: left;
  position: relative;
  // box-sizing: border-box;
  height: 31px;
  // border: 1px solid #606948;
  // background-color: transparent;
  font-size: 16px;
  outline: none;
  // padding: 5px;

  display: block;
  width: 100%;
  height: calc(1.5em + 0.3rem + 2px);
  padding: 0.15rem 0.4rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #9b9b9b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
