.business-memberships {
  .membership-plans {
    // &--list {
    // }

    &--item {
      &__content {
        height: 100%;
      }
    }
  }

  .membership-plan {
    padding: 0;
    box-shadow: 0 0.5rem 1rem rgba(41, 42, 52, 0.15);

    border: 3px solid #fff !important;
    transition: all 0.3s;
    overflow: hidden;
    cursor: pointer;
    height: 100%;

    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(41, 42, 52, 0.15);
      border-color: #7cfc00 !important;
    }

    .card-body {
      background-color: #fff;
    }

    &--title {
      text-align: left;
      display: inline-block;
      margin: 0;
      padding: 0;
      margin-bottom: 30px !important;
      color: #292a34;
      font-size: 24px;
    }

    &--price {
      text-align: center;
      color: #98ca3f;
      font-size: 24px;
    }

    .card-footer {
      background: transparent;


    }
  }

  .membership-list,
  .plan-list {
    &--item {
      background: url("../../assets/img/tick.png") no-repeat left;
      background-color: transparent !important;
      padding: 0 !important;
      padding-left: 25px !important;
      margin-bottom: 20px;
      border: 0 !important;

      line-height: 1;

      text-align: left;
      font-family: Helvetica;
      font-size: 16px;
      color: #292a34;
      letter-spacing: 0.32px;

      &:last-of-type {
        margin-bottom: 10px;
      }
    }
  }

  .agree_checkbox_register {
    text-align: left;
    margin-bottom: 1%;

    span {
      font-size: 13px;
      vertical-align: text-top;
    }
  }
}
