.checkin-btn {
  width: 30px;
  height: 30px;
}

.booked-persons {
  position: relative;
  min-height: 100px;

  .table,
  a {
    font-size: 11px;
  }
}

.cash-input-btn {
  width: 32px;
}

td {
  a {
    color: #282c37 !important;
  }
}


.dropdown {
  position: relative;
  background: #fafafa;
  border: 1px solid #eee;
  border-radius: 1px;
  max-width: 300px;

  &__arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #eee;
    border-radius: 3px;
  }

  &__list {

    max-height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;

    &--active {
      max-height: 1000px;
      opacity: 1;
      position: absolute;
      z-index: 555;
      background-color: #d9edf7;
    }
  }

  &__list-item {
    border-bottom: 1px solid #eee;
    cursor: pointer;
    list-style: none;
    padding: 8px;

    &--active {
      background: #f4f4f4;
    }

    &:hover {
      background: #f4f4f4;
    }
  }

  &__toggle {
    &:hover {
      background: #fff;
    }

    background: #fff;
  }
}
