.invoice-modal {
  .modal-content {
    .charge_date {
      text-align: right;
      font-size: 18px;
      font-weight: 600;
      margin-top: 20px;
    }

    .react-tabs__tab-list {
      text-align: left;
    }



    max-width: 1000px !important;
    position: relative;

    .partial-refund {
      .modal-content {
        max-width: 300px !important;
      }
    }

    .save-btn {
      display: inline-block;
      margin-top: 15px;
    }

    .w-200 {
      width: 200px;
    }

    table {
      .form-group {
        margin-top: auto;

        input[type="checkbox"] {
          position: static !important;
        }
      }

      .input-with {
        max-width: 100%;
      }
    }
  }

}
