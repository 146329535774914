.tennis-guy {
  width: 627px;


  img {
    max-width: 85%;
    margin-left: 30px;
  }

}
