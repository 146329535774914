.modal-cach-input {
  .form-group {
    margin-top: 0 !important;
  }

  .modal-content {
    position: relative;
  }

  .cach-input-contaier {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .each-div {
      flex: 0 0 45%;
    }
  }

  .centered {
    justify-content: center;
  }

  .cachInpotNote {
    width: 95%;
    margin-top: 4%;
    margin-left: -20px;

    input {
      width: 100% !important;
    }
  }

  .taxtTotal {
    text-align: left;
    margin-top: 3%;

    ul {
      li {
        list-style: none;
      }
    }
  }

  input {
    border: none;
    border: 1px solid #606948;
    background-color: transparent;
    font-size: 20px;
    outline: none;
    padding: 5px;
    border-radius: 3px;
    font-size: 16px;
  }
}
