header {
  .home-navbar {
    display: flex;
  }

  .header-logo {
    width: 140px;
  }

  button {
    height: 36px !important;
    border-radius: 18px !important;
  }

  padding: 15px 0;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 10px;

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-menu-btn {
      display: none;
    }

    .header-menu {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .nav-bar {
        ul {
          display: flex;

          li {
            list-style: none;
            padding: 0 15px;
            position: relative;

            a,
            span {
              text-decoration: none;
              font-family: Helvetica;
              font-size: 16px;
              color: #2b2c36;
              letter-spacing: 0.1px;
              text-align: center;
              cursor: pointer;
              white-space: nowrap;

              .badge {
                display: inline-block;
                color: tomato;
                font-size: 70%;
                vertical-align: top;

                font-weight: 700;
                line-height: 1;

                border-radius: 0.5rem;
              }
            }

            &.active {
              a {
              }

              .green-line {
                border: 1px solid #9ad308;
                display: block;
                width: 40%;
                margin: auto;
              }
            }

            &:hover {
              ul {
                display: block;
              }
            }

            ul {
              display: none;
              position: absolute;
              left: 0;
              border: 1px solid #ffffff;
              box-shadow: 0px 12px 10px 0 rgba(0, 0, 0, 0.08);
              z-index: 9;

              li {
                display: block;
                padding-top: 5px;
                padding-bottom: 5px;
                background: #ffffff;
              }
            }
          }

          &.submenu {
            padding-top: 25px;
            // position: absolute;
            max-width: 1000px;
            flex-wrap: wrap;

            li {
              padding: 0 23.5px;
            }
          }

          &.home {
            li {
              a {
                text-decoration: none;
                min-width: 165px;
                color: #ffffff;
                background-color: #9ad308;
                padding: 5px 0;
                font-size: 14px;
                border-radius: 3px;
                display: block;
                text-align: center;
                font-weight: normal;
                text-transform: unset;
              }
            }
          }
        }
      }

      .sign-btns {
        display: flex;
        align-items: center;
        align-self: start;

        h4 {
          font-size: 14px;
          color: #313234;
          font-weight: bold;
          margin-right: 10px;
          margin-left: 20px;
          display: flex;
          align-items: center;

          img {
            margin-left: 5px;
          }
        }

        ul {
          display: flex;

          li {
            list-style: none;
            padding: 0 20px;
            a:not(.btn) {
              text-decoration: none;
              font-family: Helvetica;
              font-size: 16px;
              color: #2b2c36;
              letter-spacing: 0.1px;
              text-align: center;
              cursor: pointer;
              white-space: nowrap;

              display: block;
              text-align: left;
              padding: 5px;

              &:hover {
                background-color: rgba(0, 0, 0, 0.05);
              }
            }
            a.btn {
              text-decoration: none;
              min-width: 150px;
              color: #ffffff;
              background-color: #9ad308;
              padding: 5px 0;
              font-size: 14px;
              border-radius: 3px;
              display: block;
              text-align: center;
            }

            &.log {
              padding-right: 0;
              display: flex;

              > button {
                background: #9ad308;
                border-radius: 20px;
                font-family: Helvetica;
                font-size: 16px;
                color: #ffffff;
                letter-spacing: 0.36px;
                text-align: center;
                min-width: 100px;
                border: 1px solid #9ad308;
                padding: 5px 25px;
                cursor: pointer;
                outline: none;
                height: 40px;
                font-weight: 500;

                a {
                  font-size: 16px;
                  padding: 0px 0px;
                  letter-spacing: 0.36px;
                  font-weight: 500;
                }
              }
            }

            &.white-log {
              button {
                background: #ffffff;
                color: #9ad308;
              }
            }
          }
        }

        .log-in-xs {
          display: none;
        }
      }
    }
  }
}

.line {
  p {
    height: 40px;
    background-color: red;
    color: white;
    font-size: 18px;
    text-align: center;
    align-items: center;
    padding-top: 5px;

    a {
      color: white;
    }
  }
}

.header-logo {
  a {
    text-decoration: none;

    align-items: center;

    img {
      max-width: 100%;
    }

    .logo-title {
      padding-left: 5px;

      h1 {
        font-size: 25px;
        color: #9ad308;
        font-weight: bold;

        span {
          font-weight: normal;
        }
      }
    }
  }
}

.extra-navbar {
  margin-top: 8px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  height: 91px;
  z-index: 999;
}

#spacer {
  width: 100%;
  height: 91px;
  display: block;
}

.d-block {
  display: block !important;
}

.business-switcher {
  @media all and (max-width: 768px) {
    .dropdown {
      & > .btn {
        display: none;
      }

      .dropdown-menu.dropdown-menu-right {
        position: relative;
        display: block !important;
        width: 100% !important;
        padding: 0;
        margin: 0;
        border: 0;
        z-index: 0 !important;

        a {
          text-align: center !important;
        }
      }
    }
  }
}
