.dashboard {
  .date-button {
    border-bottom: 1px solid #e1e1e1;
    display: flex;

    .date-text {
      border-right: 1px solid #e1e1e1;
      flex: 0 0 5%;
      max-width: 45px;
      text-align: center;
      padding: 3px 5px;

      span {
        font-size: 18px;
        font-weight: bold;
        color: #95d000;
      }
    }

    .date-arrow {
      flex: 0 0 95%;

      a {
        font-size: 30px;
        display: block;
        color: #535353;
        text-align: center;
        padding: 10px 0;
      }
    }
  }

  .refresh {
    position: relative;
    .notification {
      .not-icon {
        margin-left: 5px;
      }
      p {
        white-space: normal;
      }
    }
    button {
      margin-left: 5px;
      padding: 5px;

      &:not(:disabled) {
        cursor: pointer;
      }

      img {
        height: 22px;
      }

      span {
        float: right;
        margin-top: 4px;
      }

      &:disabled {
        border: 1px solid #9ad308;
        background-color: #9ad308;
      }
    }
  }

  .address {
    padding: 10px 0;
    display: flex;
    border-bottom: 1px solid #000;

    .info_address {
      flex: 1;
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          font-size: 18px;
          color: #000000;
          list-style: none;
          padding: 0 20px;
          position: relative;

          display: flex;
          align-items: center;

          &.text-center{
            font-size: 16px;

          }

          // &:after {
          //   // content: '|';
          //   position: absolute;
          //   left: 100%;
          //   top: -1px;
          //   margin-left: -2px;
          //   font-size: 18px;
          //   color: #000000;
          // }

          &:last-child {
            &:after {
              display: none;
            }
          }

          img {
            display: block;
            margin-left: 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  // .edit-btn {
  //   border-top: 1px solid #000000;
  //   border-bottom: 1px solid #000000;
  //   position: relative;

  //   h2 {
  //     display: block;
  //     font-size: 17px;
  //     color: #000000;
  //     padding: 10px 0;
  //     text-align: center;
  //   }
  // }

  .table-content {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    position: relative;

    .lr-container {
      max-width: 345px;
      min-width: 240px;
      text-align: center;
      position: relative;
      right: 0;
      padding-right: 5px;
      padding-left: 5px;

      .lr-wrapp {
        display: flex;
        flex-direction: column;

        height: 100%;
        position: relative;
      }
    }

    .recurrence {
      width: 270px;
      margin: 0 auto;

      .recurrence-title {
        h4 {
          text-align: center;
        }
      }

      .recurrence-content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .form-group {
          margin: 10px 3px 0;

          input {
            visibility: hidden;
            position: absolute;

            &:checked + label {
              background-color: #9ad308;
            }
          }

          label {
            display: block;

            &.label-text {
              font-size: 9px;
            }

            &.label-icon {
              width: 25px;
              height: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #eff0f2;
              font-size: 12px;
              color: #000;
              margin: 0 auto;

              &:after {
                display: none;
              }
            }
          }
        }
      }

      .recurrence-btn {
        padding-top: 20px;

        ul {
          display: flex;
          justify-content: space-between;
          background-color: #9ad308;

          li {
            list-style: none;
            flex: 0 0 50%;
            padding: 10px 0;
            position: relative;

            &::after {
              content: '|';
              color: #ffffff;
              position: absolute;
              left: 100%;
              top: 3px;
              font-size: 27px;
            }

            button {
              background-color: transparent;
              border: 0;
              color: #ffffff;
              font-size: 16px;
            }

            &:last-child {
              border: 0;
            }
          }
        }
      }
    }

    .table-container {
      flex: 0 0 auto;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% - 250px);
      position: relative;
      overflow-x: auto;
    }

    .table {
      .dash-head {
        display: flex;

        h4 {
          max-width: 80px;
          flex: 0 0 80px;
          background-color: transparent;
          border: none;
          color: #000000;
          font-weight: bold;
          padding: 8px;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        ul {
          display: flex;
          flex: 0 0 calc(100% - 80px);

          li {
            list-style: none;
            font-size: 17px;
            text-align: center;
            padding: 8px;
            color: #000000;
            font-weight: bold;
            min-width: 59.5px;
            margin: 2px;

            span {
              display: block;
              font-size: 12px;
              font-weight: normal;

              a {
                text-decoration: none;
                color: #09b1e7;
                font-size: 11px;
              }
            }
          }
        }
      }

      .dash-body {
        height: calc(100vh - 280px);
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        .dash-row {
          display: flex;

          h4 {
            max-width: 80px;
            flex: 0 0 80px;
            background-color: transparent;
            border: none;
            color: #000000;
            font-weight: bold;
            padding: 8px;
            font-size: 16px;
            display: block;
            text-align: center;

            .form-group {
              padding-top: 5px;
            }
          }

          ul {
            display: flex;
            flex: 0 0 calc(100% - 80px);
            max-width: calc(100% - 80px);

            li {
              height: 80px;
              text-align: center;
              background-color: #e5e8ee;
              margin: 2px;
              font-size: 15px;
              color: #ffffff;
              list-style: none;
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 59.5px;
              user-select: none;

              &.booked {
                //background-color: #35e709;
                background-color: #95d000;
                text-transform: capitalize;
                border-collapse: collapse;
                position: relative;
                display: inline-block;
                text-align: center;

                span {
                  display: block;
                  overflow: hidden;
                  padding-top: 15px;
                }

                span:last-child {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  font-size: 11px;
                  padding: 0px 3px;
                  text-align: center;
                  margin: 0px auto;
                  white-space: normal;
                  height: 30px;
                }

                &.all {
                  border: 2px solid #c8121d;
                }

                &:after {
                  position: absolute;
                  width: 100%;
                  height: 2px;
                  background-color: #95d000;
                  border-left: 2px solid #c8121d;
                  border-right: 2px solid #c8121d;
                  left: -2px;
                }

                &.top {
                  border-top: 2px solid #c8121d;
                  border-left: 2px solid #c8121d;
                  border-right: 2px solid #c8121d;

                  &:after {
                    content: '';
                    top: 100%;
                  }
                }

                &.bottom {
                  border-left: 2px solid #c8121d;
                  border-right: 2px solid #c8121d;
                  border-bottom: 2px solid #c8121d;

                  &:after {
                    content: '';
                    bottom: 100%;
                  }

                  &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: #95d000;
                    border-left: 2px solid #c8121d;
                    border-right: 2px solid #c8121d;
                    bottom: 100%;
                    left: -2px;
                  }
                }

                &.side {
                  border-left: 2px solid #c8121d;
                  border-right: 2px solid #c8121d;

                  &:after {
                    content: '';
                    bottom: 100%;
                  }

                  &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: #95d000;
                    border-left: 2px solid #c8121d;
                    border-right: 2px solid #c8121d;
                    top: 100%;
                    left: -2px;
                  }
                }

                &.notavaliable {
                  opacity: 0.5;
                  pointer-events: none;
                }
              }

              &.listed {
                background-color: #09b1e7;
                text-transform: capitalize;
                position: relative;

                //Listed Frame
                &:after {
                  position: absolute;
                  width: 100%;
                  height: 2px;
                  background-color: #09b1e7;
                  border-left: 2px solid #95d000;
                  border-right: 2px solid #95d000;
                  left: -2px;
                }

                &.top {
                  border-top: 2px solid #95d000;
                  border-left: 2px solid #95d000;
                  border-right: 2px solid #95d000;

                  &:after {
                    content: '';
                    top: 100%;
                  }
                }

                &.bottom {
                  border-left: 2px solid #95d000;
                  border-right: 2px solid #95d000;
                  border-bottom: 2px solid #95d000;

                  &:after {
                    content: '';
                    bottom: 100%;
                  }
                }

                &.side {
                  border-left: 2px solid #95d000;
                  border-right: 2px solid #95d000;

                  &:after {
                    content: '';
                    bottom: 100%;
                  }

                  &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: #09b1e7;
                    border-left: 2px solid #95d000;
                    border-right: 2px solid #95d000;
                    top: 100%;
                    left: -2px;
                  }
                }

                &.notavaliable {
                  opacity: 0.5;
                  pointer-events: none;
                }
              }

              &.pending {
                background-color: #ff8600;
              }

              &.past {
                opacity: 0.3 !important;
              }
            }
          }
        }
      }
    }
  }

  .rec-btn {
    padding-bottom: 10px;

    ul {
      display: flex;
      margin: 0 -2px;

      li {
        list-style: none;
        flex: 0 0 50%;
        padding: 0 2px;
        border: none;

        &:after {
          display: none;
        }

        button {
          display: block;
          text-decoration: none;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          padding: 10px;
          background-color: #95d000;
          width: 100%;
          border: none;
          cursor: pointer;
        }

        &.cancel {
          button {
            background-color: #f18005;
          }
        }

        &.save {
          button {
            background-color: #0ab1e7;
          }

          &.send-message {
            padding-right: 10px;
            text-align: right;

            button {
              display: inline-block;
              width: auto;
              padding: 10px 15px;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }

  .bottom-btns {
    padding: 20px;
    margin: 0 auto;

    button {
      display: block;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      padding: 10px;
      width: 100%;
      border: none;
      cursor: pointer;
    }

    .cancel-btn {
      button {
        background-color: #f18005;
      }
    }

    .save-btn {
      padding-top: 20px;

      button {
        background-color: #09b1e7;

        &:disabled {
          background-color: #535353;
        }
      }
    }

    .save-book-btn {
      padding-top: 20px;

      button {
        background-color: #9ad308 !important;

        &:disabled {
          background-color: #535353;
        }
      }
    }
  }
  .zIndexMax{
    z-index: 9999 !important;
  }
  .toggle-date-btn {
    display: none;

    p {
      font-size: 12px;
    }
  }
}

.rec-form {
  padding-bottom: 10px;
  display: flex;
  align-items: center;

  .form-group {
    margin-top: 0;
    display: flex;

    &:first-child {
      flex: 0 0 70%;
      padding-right: 10px;
    }

    &:last-child {
      flex: 0 0 100%;
    }

    label {
      font-size: 14px;
      color: #333;
      text-align: left;

      .important-span {
        color: red;
        display: contents;
        margin-right: 3px;
      }

      &.label-icon {
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid #606948;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        order: -1;

        &:after {
          content: '';
          background-image: url('../../img/check.png');
          position: absolute;
          top: 0;
          left: -1px;
          width: 100%;
          height: 100%;
          opacity: 0;
          background-size: cover;
          background-position: center;
        }
      }
    }

    input[type='checkbox'] {
      position: absolute;
      visibility: hidden;
      width: 0;

      &:checked ~ label {
        &.label-icon {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.listing-text {
  padding: 15px 0;

  p {
    text-decoration: underline;
    font-size: 15px;
  }
}

.dashboard.closed .table-content .lr-container {
  position: absolute;
  right: -300px;
  transition: all 0.4s ease-in;
  background-color: #f2f3f6;
  padding: 8px 15px 15px;
  z-index: 5;

  &.opened {
    right: 0;
  }
}

.dashboard.closed .table-content {
 // overflow: hidden;
  position: relative;
}

.dashboard.closed .table-content .table-container {
  flex: 0 0 calc(100% - 60px);
}

.dashboard.closed .toggle-date-btn {
  display: block;
  position: absolute;
  z-index: 5;
  top: 10px;
  right: 0;
  transition: all 0.4s ease-in;

  p {
    text-align: center;
    background-color: #f2f3f6;
  }

  button {
    border: none;
    cursor: pointer;
    background-color: #f2f3f6;
    padding: 8px;
    transition: all 0.5s;

    img {
      transition: all 0.5s;
    }

    &:hover {
      background-color: #e5e8ee;
    }
  }

  &.opened {
    right: 300px;

    button {
      img {
        transform: rotate(180deg);
      }
    }

    p {
      display: none;
    }
  }
}

.dashboard.closed .bottom-btns {
  position: static;
}

.dashboard {
  .table {
    .form-group {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        visibility: hidden;
        position: absolute;
      }

      label {
        cursor: pointer;
        user-select: none;
      }

      label.label-icon {
        display: inline-block;
        order: 2;
        margin-right: 0;
        margin-left: 5px;
        width: 16px;
        height: 16px;
      }
    }

    .dash-head {
      position: relative;

      h4 {
        padding: 8px 2px !important;
        position: relative;
        flex-wrap: wrap;

        .form-group {
          text-align: center;

          label {
            font-size: 14px;
            flex: 0 0 42px;
            border-radius: 0;

            &.label-icon {
              flex: 0 0 16px;
            }
          }
        }
      }
    }

    label {
      border-radius: 0 !important;
    }
  }
}
