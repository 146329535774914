.group-modal {
  .modal-content {
    .form-group {
      margin-bottom: 15px;
      padding: 0 10px;
      text-align: left;

      label {
        font-weight: bold;
        padding-right: 5px;
      }
    }

    .modal-form-group {
      h5 {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.group {
  //overflow: auto;

  .group-item {
    min-width: 520px;
    display: grid;
    grid-template-columns: 150px auto 100px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;

    span {
      display: block;
    }

    .lable-parth {
      span {
        font-weight: bold;
      }
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 16px;
      /* fallback */
      max-height: 32px;
      /* fallback */
      -webkit-line-clamp: 2;
      /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .go-to-group {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        text-decoration: none;
        display: inline;
        color: green;
      }
    }
  }
}

.GroupItemPage {
  .group-item-members-list {
    .group-item-memeber-item {
      display: grid;
      grid-template-columns: 50px 150px auto 100px;
      border: 1px solid black;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;

      span {
        display: block;
      }

      .lable-parth {
        span {
          font-weight: bold;
        }
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;
        /* fallback */
        max-height: 32px;
        /* fallback */
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      .appeircheckbox {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .go-to-group {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          text-decoration: none;
          display: inline;
          color: green;
        }
      }
    }

    .AppeirParth {
      display: grid;
      grid-template-columns: 50px 150px 0.3fr 1fr;
      padding: 10px;
      margin-top: 10px;
      border: 1px solid black;
      border-radius: 5px;

      .appeircheckbox {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      input {
        border: 1px solid #606948;
        border-radius: 5px;
        padding: 5px;
      }
    }
  }

  .group-item-update {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .modal-form-group {
      padding: 5px 10px;
      position: relative;

      .char-count {
        font-size: 10px;
      }

      textarea {
        width: 100%;
        height: 100px;
        resize: none;
        border: 1px solid #606948;
        background-color: transparent;
        border-radius: 3px;
        padding: 5px;
        padding-right: 25px;

        &.error {
          border-color: #ff0000;
        }
      }

      .notification {
        span {
          top: 30px;
          bottom: auto;
          margin-left: -35px;
        }

        p {
          margin-left: -40px;
          margin-bottom: -10px;
        }
      }
    }

    .form-group {
      margin-top: 0;
      padding: 5px 10px;
    }

    .group-item-update-buutton {
      display: flex;
      align-items: center;
      justify-content: left;

      button {
        text-align: center;
        outline: none;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        background-color: #9ad308;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        padding: 5px 0;
        border: none;
        min-width: 120px;

        &:disabled {
          background-color: #606948;
        }
      }
    }
  }
}

.GroupMemberPage {
  .selfParth {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .need-padding {
      div {
        padding: 5px;
        ;
      }
    }

    div {


      &.stripePart {
        grid-row-start: 1;
        grid-row-end: 6;
        grid-column-start: 2;
        grid-column-end: 3;
        display: flex;
        align-items: center;
        justify-content: center;



        ul {
          list-style: none;

          li {
            padding: 15px 0;
          }
        }
      }

      // &.stripehystory{
      //   grid-row-start: 1;
      //   grid-row-end: 6;
      //   grid-column-start: 3;
      //   grid-column-end: 4;
      //   border: 1px solid black;
      //   border-radius: 5px;
      //   overflow-y: auto;
      // }
      &.WaiverParth {
        grid-row-start: 7;
        grid-row-end: 8;
        grid-column-start: 1;
        grid-column-end: 4;
        display: flex;
        align-items: center;

        label {
          input {
            width: unset;
          }
        }
      }

      &.noteAndWaiverParth {
        grid-row-start: 1;
        grid-row-end: 6;
        grid-column-start: 3;
        grid-column-end: 4;
        display: flex;

        label {
          width: 100%;

          textarea {
            resize: none;
          }
        }
      }

      label {
        input {
          padding: 5px;
          border: 1px solid #606948;
          border-radius: 5px;
          width: 100%;
        }

        textarea {
          padding: 5px;
          border: 1px solid #606948;
          border-radius: 5px;
          width: 100%;
        }
      }
    }
  }

  .groupParth {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: minmax(100px, 350px);

    div {
      overflow-y: auto;
    }

    li {
      list-style: none;
    }
  }

  .parentPart {
    padding-top: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    div {
      padding: 5px;

      label {
        input {
          padding: 5px;
          border: 1px solid #606948;
          border-radius: 5px;
          width: 100%;
        }
      }
    }

    .sendMEssage {
      display: flex;
      align-items: flex-end;

      button {
        padding: 5px;
        border: 1px;
      }
    }

    .if18 {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 5;
    }
  }

  .addorsaveGroupMember {
    text-align: center;
    padding: 10px 0;
  }
}
