.form-group-booking-step {
  display: flex !important;

  input {
    flex: 0 0 40% !important;
  }

  .btn {
    margin-left: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  @media all and (max-width: 575px) {
    flex-wrap: wrap;

    label {
      flex: 0 0 100% !important;
    }

    input {
      flex: 0 0 75% !important;
    }
  }
}
