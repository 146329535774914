table.minimalistBlack {
  margin: 100px 0 50px 0;
  width: 100%;
  text-align: center;
  border: 0px;
}

table.minimalistBlack td,
table.minimalistBlack th {
  padding: 17px 40px;
}

table.minimalistBlack tbody td {
  border: 3px solid #f6f6f6;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  width: 200px;
  color: #9ad308;

  >img {
    width: 20px;
    height: 14px;
  }

  .price {
    color: #9ad308;
    font-size: 25px;
    padding-top: 20px;
    padding-bottom: 28px;
    width: unset;
  }

  .sign-up {
    color: #00c6ff;
    font-size: 16px;
    font-style: italic;
    text-decoration: underline;
    width: unset;
    cursor: pointer;

    &.hide {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}

table.minimalistBlack tbody td.text {
  padding: 15px 5px 15px 5px;
  max-width: 350px;
  text-align: left;
  width: unset;
  color: unset;
  position: relative;
}

table.minimalistBlack tbody td.left-border {
  background: #9ad308;
  padding: 7px;
  border: unset;
  width: unset;

  &.top-radius {
    border-top-left-radius: 8px;
  }

  &.bottom-radius {
    border-bottom-left-radius: 8px;
  }
}

table.minimalistBlack tbody td.hide-td {
  padding: 0;
  border: unset;
  width: unset;

  .demo-txt {
    color: black;
    font-weight: bolder;
    font-size: 15px;
  }
}

table.minimalistBlack thead {
  background: #9ad308;
}

table.minimalistBlack thead tr.pad {
  >th {
    padding: 20px 0;
  }
}

table.minimalistBlack thead th {
  border-left: 3px solid #f6f6f6;
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  padding: 10px 0;
  color: white;
}

table.minimalistBlack thead tr th.hide-head {
  padding: 0;
  background: white;
  border: unset;
  position: relative;

  .demo-btn {
    border: 2px solid #9ad3087a;
    color: #00c6ff;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    font-size: 18px;
    right: 0;
    max-width: 185px;
    border-radius: 6px;
    padding: 8px;
    cursor: pointer;
  }
}

table.minimalistBlack thead tr th.right-radius {
  border-top-right-radius: 15px;
}

table.minimalistBlack thead tr th.left-radius {
  border-top-left-radius: 15px;
  border-left: unset;
}
