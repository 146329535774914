footer {
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .footer-menu {
      ul {
        display: flex;

        li {
          list-style: none;
          padding: 0 25px;

          button {
            font-size: 14px;
            color: #ffffff;
            font-weight: bold;
            cursor: pointer;
            border: none;
            background-color: transparent;
            outline: none;
          }
        }
      }
    }

    .footer-contact {
      h4 {
        font-size: 14px;
        color: #ffffff;
        font-weight: bold;
        padding-bottom: 10px;
      }

      ul {
        display: flex;

        li {
          list-style: none;
          padding-right: 20px;
        }
      }
    }
  }

  .footer-copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .address-footer {
      margin-right: 20px;
    }

    p {
      font-family: Helvetica;
      font-size: 12px;
      color: #878f9d;
      letter-spacing: 0;
      line-height: 21px;
      padding-top: 10px;
      padding-bottom: 10px;
      white-space: nowrap;
    }
  }
}
