.promo-div {
  text-align: center;
  margin-top: 10px;

  input {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    outline: none;
    font-size: 16px;
    vertical-align: middle;
    padding: 7px;
    font-size: 18px;
  }




  button {
    background: #1bb1e4;
    border: none;
    outline: none;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    line-height: 1;
    padding: 0;
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
    vertical-align: middle;
  }
}

.modal-btns {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 15px;
    margin-right: 15px;

  }
}

button:disabled {
  opacity: 0.4;
}
