.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item {}

.react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item:after {
  content: "\25B6";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.court-sheet {
  .rbc-btn-group {
    // display: none;
  }

  .rbc-time-content {
    max-height: 750px;
  }

  .rbc-selected-range-bg {
    background-color: darken(#eef7ff, 5);
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: unset;
  }

  .rbc-event-content a {
    color: #265985;
    display: block;
  }

  a {
    padding: 5px 0;

    font-size: 12px;
    color: #000000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .rbc-current-time-indicator {
    //display: none;
  }

  .rbc-today {
    background: unset;
  }

  .rbc-time-gutter-value {
    display: flex;
    //margin-top: 10px;
  }

  .rbc-allday-cell input {
    display: block;
    margin: auto;
  }

  .rbc-allday-cell {
    display: none;
  }

  //.rbc-time-header-gutter {
  //  width: 70px !important;
  //  max-width: 70px !important;
  //}
  .rbc-event {
    z-index: 2;
  }

  .coach_availability {
    .rbc-event:not(.rbc-event--disabled) {
      z-index: 1;
    }
  }

  .microsettings {
    .rbc-event {
      z-index: 0;
    }
  }

  .colored-event {
    .rbc-event {
      left: 0 !important;
    }
  }

  .white-style {
    
    .rbc-event {
      border-radius: 0;
      // border-left: 0;
      border-right: 0;
      border-color: #ddd;
      z-index: 4 !important;

      &:before {
        content: "Booked";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgb(255, 96, 92) !important;
        color: #000;
        padding-left: .5%;
        padding-top: .5%;
        font-weight: bold;
         font-size: 10px;
      }

      &:after {
        background-color: transparent !important;
      }
    }

    &.disabled-event {
      .rbc-event {
        &:before {
          background-color: #e6e6e6;
        }
      }
    }
    .rbc-event-blocked{
      z-index: 11 !important;

      &:before{
        content: "Blocked" !important;
      }
    }
    .rbc-event-booked{
      z-index: 30 !important;

      &:before{
        content: "Booked" !important;
      }
    }
  }
.coachName{
  //margin-top: 9%;
  display: block;
}
  .disabled-event {
    .rbc-event {
      opacity: 1;

      &:after {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.07);
      }
    }
  }

  .rbc-event {
    background: #eef7ff;
    width: 100% !important;
    border-color: darken(#eef7ff, 100);

    * {
      color: black;
    }
  }
}

.d-block {
  display: block;
}

[disabled] {
  opacity: 0.5;
}

.small-event {
  font-size: 8px;

  .rbc-event-content {
    padding-top: 10px;
    font-size: 8px;
  }
}
