#footer {
  margin-top: 107px;

  .footer-copyright {
    background-image: linear-gradient(223deg, rgba(31, 144, 20, 0.2) 0%, rgba(89, 185, 0, 0.1) 100%);
    margin-top: 75px;
  }

  .footer-logo {
    flex: 0 0 25%;

    .logo-div {
      width: 140px;

      img {
        max-width: 100%;
      }
    }
  }

  .footer-menu {
    flex: 0 0 75%;

    ul {
      flex-wrap: wrap;

      li {
        flex: 0 0 20%;
        padding-bottom: 10px;
        padding-top: 10px;

        a {
          font-family: Helvetica;
          font-size: 14px;
          color: #878F9D;
          letter-spacing: 0;
          line-height: 21px;
          list-style-type: none;
          text-decoration: none;
          cursor: pointer;

          &:hover {
            color: #9AD308;
          }
        }
      }
    }
  }

  .schedule-div {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-menu {
    ul {
      padding-bottom: 0 !important;
    }
  }

  .footer-copyright {
    padding-top: 20px !important;
    padding-bottom: 10px;

    p {
      display: block !important;
    }
  }

}
