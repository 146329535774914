.welcome-page {
  background-image: linear-gradient(
    180deg,
    rgba(90, 186, 13, 0.53) 0%,
    rgba(169, 211, 135, 0.57) 21%,
    rgba(237, 247, 229, 0.88) 60%,
    #d3e7c4 100%
  );
  height: calc(100vh - 112px);

  .padding-container {
    height: calc(100vh - 112px);

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.new-button {
  a {
    cursor: pointer;
    border: none;
    background: #9ad308;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
    border-radius: 56px;
    font-family: Helvetica;
    font-size: 22px;
    color: #ffffff;
    font-weight: 600;
    // width: 199px;
    height: 66px;
    text-decoration: none;
    display: block;
    text-align: center;
    line-height: 3;
    white-space: nowrap;
    padding: 0 15px;
  }
}

@media (max-width: 768px) {
  .welcome-page {
    .padding-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .tennis-guy {
        width: 100%;
      }

      .new-button {
        margin-top: 20px;
        margin-bottom: 20px;

        &:first-child {
          margin-bottom: 10px;
        }

        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }
}
