.athlete-options-container {
  .main-container {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .m-bottom-40 {
    margin-bottom: 40px;
  }

  .athlete-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 60px;
    margin-bottom: 60px;

    .option,
    .options-desc {
      flex: 0 0 26%
    }

    .option {
      background: #FFFFFF;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.50);
      border-radius: 19px;
      font-family: Helvetica;
      font-size: 22px;
      display: flex;
      align-items: center;
      padding-top: 15px;
      padding-bottom: 6px;
      padding-left: 15px;

      .option-img-div {
        padding-right: 15px;
        width: 75px;

        img {
          max-width: 100%;
        }
      }

      .option-desc-div {

        font-size: 16px;
        font-family: Helvetica;
        text-align: left;

      }


    }

    .option_3 {
      .option-desc-div {
        text-align: left;
        padding-left: 15px;
      }

      .option-img-div {
        img {
          width: 64px;
          max-width: 64px;
        }
      }

    }

    .options-desc {
      font-family: Helvetica;
      font-size: 24px;
      color: #353535;
      line-height: 32px;
      font-weight: 600;
      margin-bottom: 35px;
    }
  }

}


@media (max-width: 768px) {

  .athlete-options-container {

    .main-container {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .athlete-options {
      flex-direction: column;

      .screen {
        margin-bottom: 20px;
      }
    }



    .athlete-options .option {
      width: 100%;
      margin-bottom: 20px;
    }
  }






}
