.mobile-screens {
  .screen {
    flex: 0 0 24%;
  }

  display: flex;
  justify-content: space-around;
  align-items: center;

  img {
    max-width: 100%;
    border-radius: 46px;
  }
}

.mobile-screens-section {
  background: rgba(35, 187, 249, 0.64);

  .title {
    font-family: Helvetica;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    line-height: 36px;
    padding-top: 30px;
    padding-bottom: 50px;
  }

  .main-container {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .no-padding-top {
    padding-top: 0 !important;
  }

  .appstore {
    img {
      max-width: 140px;
    }
  }

  .appstore {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .stars {
    padding-bottom: 40px;

    img {
      max-width: 20px;
    }
  }

  .appstore,
  .stars {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .mobile-screens-section {
    .mobile-screens {
      flex-direction: column;

      .screen {
        margin-bottom: 20px;
      }
    }

    .mobile-screens {
      .screen {
        flex: 0 0 100%;
      }
    }

    .main-container {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}
