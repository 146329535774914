.form-group-rounded {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  textarea,
  input[type="number"] {
    width: 100%;
    border: none;
    border: 1px solid #e9e9e9;
    border-radius: 24px;
    background-color: transparent;
    outline: none;
    padding: 12px;
    font-size: 12px;
    position: relative;
    background-repeat: no-repeat;
    background-position: left;
    background-position-x: 15px;
    padding-left: 30px;

    &:not(.optional-field) {
      background-image: url("../../assets/img/red-star.png");
    }
  }

  input[type="text"].error,
  input[type="password"].error,
  input[type="email"].error,
  textarea.error,
  input[type="number"].error {
    border-color: red;
  }

  input::placeholder {
    color: #262733;
    font-size: 11px;
  }
}
