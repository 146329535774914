.tags-modal {
  width: 100%;
  max-width: 700px !important;
  text-align: left !important;
}
.anArchiveBtn{
  button{
    height: auto !important;
    border-radius: 3px !important;
    font-size: 13px;
  }
}
.tags-list {
  .table {
    tr {
      th,
      td {
        text-align: center;
      }
    }
  }

  .btn-create-tag {
    display: inline;
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 40px;
  }
}
