.pricing-title {
  margin-top: 64px;
  flex: 0 0 100%;
  text-align: center;

  h2 {
    font-family: Helvetica;
    font-size: 30px;
    color: #292a34;
    letter-spacing: 0.71px;
    text-align: center;
    line-height: 50px;
  }
}

.pricing-page {
  .main-container {
    // padding-left: 150px;
    // padding-right: 150px;
    padding: 0;
    margin: 0 auto;
    max-width: 90%;
  }
}

.inner-pricing-title {
  width: 230px;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  position: relative;

  .price {
    font-family: Helvetica;
    font-size: 30px;
    color: #292a34;
    letter-spacing: 0.54px;
    line-height: 22px;
  }

  .discount {
    position: relative;
    color: grey;
    font-size: 20px;
    margin-right: 15px;

    span {
      display: none;
      position: absolute;
      width: 41px;
      border-bottom: 2px solid gray;
      left: 0;
      top: 10px;
      transform: rotate(339deg);
    }

    .display_visible {
      display: inline;
    }
  }

  .discountSale {
    ont-family: Helvetica;
    font-size: 30px;
    color: #292a34;
    letter-spacing: 0.54px;
    line-height: 22px;
  }

  .discountAlert {
    color: red;
    font-size: 10px;
  }

  .currency {
    //position: absolute;
    font-family: Helvetica;
    font-size: 12px;
    color: #292a34;
    letter-spacing: 0.21px;
    line-height: 22px;
    // top: 0px;
    // left: 5px;
    vertical-align: super;
    margin-right: 3%;
  }

  .line {
    margin-left: 2px;
    margin-right: 2px;
    color: #9ad308;
  }

  .duration {
    font-family: Helvetica;
    font-size: 14px;
    color: #292a34;
    letter-spacing: 0.25px;
    margin-left: 2px;
  }
}

.each-feature {
  background: url("../../assets/img/tick.png") no-repeat left;
  padding-left: 30px;
  margin-bottom: 20px;
  text-align: left;
  font-family: Helvetica;
  font-size: 16px;
  color: #292a34;
  letter-spacing: 0.32px;
  text-transform: none;
  background-position: top left;

  &::first-letter {
    text-transform: capitalize;
  }

  @media all and (max-width: 992px) {
    margin-bottom: 5px;
  }
}

.plans-header {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  margin-bottom: 30px;

  .plans-header--item {
    flex: 0 0 79%;
    color: #a4d621;

    &:first-child {
      flex: 0 0 19%;
      color: rgba(35, 187, 249, 0.64);
    }

    h4 {
      display: block;
      width: 100%;
      margin: 0;
      text-align: center;
    }
  }
}

.plans-list {
  display: flex;
  // justify-content: space-around;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  flex-wrap: wrap;
  height: 730px;
  gap:40px;
}

.plan {
  background: #9ad308;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  padding-top: 10px;
  padding-left: 10px;
  flex: 0 0 19%;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  &.plan-blue {
    background-color: rgba(35, 187, 249, 0.64);

    .inner-plan .sign-up {
      background-color: rgba(35, 187, 249, 0.64);
      border-color: rgba(35, 187, 249, 0.64);
    }

    .radius-circle {
      background-color: rgba(35, 187, 249, 0.64);
    }
  }

  .plan-title {
    font-family: Helvetica;
    font-size: 20px;
    color: #292a34;
    letter-spacing: 0.39px;
    text-align: center;
    line-height: 22px;
    width: 80%;
    margin: auto;

    span {
      display: block;
      margin: 0 auto;
      border: 1px solid #9ad308;
      width: 20%;
      margin-top: 6px;
    }
  }

  .inner-plan {
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    padding: 15px;
    position: relative;
    padding-bottom: 86px;
    overflow: hidden;

    height: 100%;

    .sign-up {
      background: #9ad308;
      border-radius: 20px;
      font-family: Helvetica;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0.36px;
      text-align: center;
      max-width: 170px;
      border: 1px solid #9ad308;
      padding: 5px 31px;
      cursor: pointer;
      outline: none;
      height: 36px;
      font-weight: 500;
      position: absolute;
      bottom: 25px;
    }
  }
}

.update-plan-list {
  justify-content: center !important;

  .plan {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.features-list {
  margin-top: 30px;
  min-height: 280px;
}

.radius-circle {
  background: #a4d621;
  height: 250px;
  width: 250px;
  position: absolute;
  right: -120px;
  border-radius: 50%;
  bottom: -160px;

  @media all and (max-width: 992px) {
    display: none;
  }
}

.info-plans {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 50px;

  @media all and (max-width: 992px) {
    text-align: left !important;
  }
}

.info-plans,
.all-features-list {
  max-width: 75%;
  margin: 0 auto;

  @media all and (max-width: 992px) {
    max-width: 100% !important;
  }
}

.all-features-list {
  margin-top: 120px;
}

.features-list-container {
  background: #9ad308;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  padding-top: 14px;
  padding-left: 18px;
  margin-top: 100px;

  .inner-features-list-container {
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    padding: 25px;
    position: relative;
    padding-bottom: 86px;
    overflow: hidden;

    .each-feature {
      text-transform: initial;
    }
  }
}

.inner-features-list {
  width: 1000px;
  margin: 0 auto;
  margin-top: 50px;

  .each-feature {
    padding-left: 50px;
  }
}

.schedule-form-in-price {
  margin-top: 106px;
}
@media all and (max-width: 1733px) {
  .plan{
    height: 110%;
  }
}
@media all and (max-width: 450px) {
  .pricing-page{
   .pricing-title{
      margin-top: 150px;
   }
   .plans-list{
      margin-top: 10px;
   }
  }
}
@media all and (max-width: 1400px) {
  .plans-header {
    .plans-header--item {
      flex: 0 0 65%;

      &:first-child {
        flex: 0 0 32%;
      }
    }
  }

  .plans-list {
    height: 1300px;
    gap:18px;
  }

  .plan {
    flex: 0 0 32%;
    height: 61%;
  }
}

@media (max-width: 995px) {
  .pricing-page {
    .pricing-title h2 {
      line-height: 1;
    }

    .all-features-list {
      margin-top: 50px;
    }

    .inner-features-list {
      width: 100%;
    }

    .main-container {
      padding-left: 15px;
      padding-right: 15px;
    }

    .plans-header {
      display: none;
    }

    .plans-list {
      margin-top: 50px;
      flex-direction: column;
      height: auto;

      .plan {
        height: auto;
        margin-bottom: 20px;
      }
    }
  }
}
