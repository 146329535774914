.tags-modal {
  width: 100%;
  max-width: 700px !important;
  text-align: left !important;
}

.tags-list {
  .table {
    tr {
      th,
      td {
        text-align: center;
      }
    }
  }

  .btn-create-tag {
    display: inline;
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 40px;
  }
}
