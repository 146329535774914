.micro-settings {
  max-width: 600px;
  margin: auto;

  label[for='weekdaysWrapper'] {
    text-align: center;
    width: 100%;
    display: block;
  }

  .form-group {
    margin-bottom: 15px !important;
  }

  .date-picker,
  .table-item {
    max-width: 100px;
  }

  .table {
    tr {
      th,
      td {
        text-align: center;
      }
    }
  }
}

.d-flex {
  display: flex;
}

.mr-2 {
  margin-right: 10px;
}

.mx-auto {
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.justify-center {
  justify-content: center;
}
