.app-shop-route {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  display: block !important;

}
