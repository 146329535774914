.bb-video-modal {
  .modal-content {
    max-width: 800px !important;
    width: 800px !important;
    height: 500px;

    .auth-container {
      width: 100% !important;
    }
  }

  .video-bb iframe {
    width: 700px;
    height: 450px;
  }

}

@media (max-width: 768px) {
  .bb-video-modal {
    .modal-content {
      height: auto !important;
    }
  }

  .video-bb iframe {
    width: auto !important;
    height: auto !important;
  }
}
