.lessons-switcher {
  .form-group {
    display: flex;

    .onoff-text {
      min-width: 85px;
    }
  }
}

.lessons-price {
  display: flex;
  justify-content: space-between;
  width: 100%;

  input[type="text"] {
    width: 100%;
  }

  >.form-group {
    display: flex;

    label {
      padding-right: 10px;
    }
  }
}

.modal .modal-content {
  .lessons-note {
    text-align: left;
    padding-top: 10px;

    .modal-form-group {
      padding: 0;

      h5 {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 5px;
      }

      .notification {
        span {
          margin-left: -25px;
        }

        p {
          margin-left: -30px;
        }
      }
    }
  }
}

.lessons-quantity {
  .form-group {
    display: flex;

    label {
      white-space: nowrap;
      padding-right: 5px;
    }
  }
}

.container {
  .lessons {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    .lessons-list {
      flex: 0 0 70%;

      .lesson-item {
        border: 2px solid #606948;
        padding: 5px 8px;
        margin-top: 10px;
        border-radius: 5px;
        display: flex;

        &.full {
          border-color: #9ad308;
        }

        &.notfull {
          border-color: orange;
        }

        &.empty {
          border-color: orange;
        }

        .lesson-item-proprty-group {
          flex: 0 0 50%;

          .lesson-item-proprty {
            display: flex;

            span:first-child {
              min-width: 130px;
            }

            .add-booked {
              cursor: pointer;
              color: blue;
            }
          }
        }

        .lesson-item-markAs {
          flex: 0 0 25%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          input {
            border: 1px solid #606948;
            background-color: transparent;
            font-size: 20px;
            outline: none;
            padding: 5px;
            -webkit-border-radius: 3px;
            border-radius: 3px;
            font-size: 16px;
            max-width: 165px;
          }

          button {
            margin-top: 5px;
          }
        }

        .lesson-item-more {
          flex: 0 0 25%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            padding-top: 10px;
            cursor: pointer;
          }
        }
      }
    }

    .lessons-dataPicker {
      flex: 0 0 30%;
      text-align: center;
    }
  }
}

.lesson-form-group {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  flex-direction: column;
  padding-left: 0;

  label {
    display: flex;
    justify-content: start !important;

    .important-span {
      color: tomato;
    }
  }

  input {
    border: none;
    border: 1px solid #606948;
    background-color: transparent;
    font-size: 20px;
    outline: none;
    padding: 5px;
    border-radius: 3px;
    font-size: 16px;

    &.error {
      border-color: red;
    }
  }
}

.pad-l-no {
  padding-left: 0 !important;
}

.pad-r-no {
  padding-right: 0 !important;
}

.border-left-2-gray {
  border-right: 2px solid #333;
}

.dates-table-multiple {
  thead {
    tr {
      td {
        border: none;
      }
    }
  }
}

.lesson-create-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 15px;

  .scroll-container-dates {
    margin-bottom: 15px;
  }

  .lesson-submit {
    position: absolute;
    bottom: 0;
    right: 0;

    width: 100%;
    display: flex;
    justify-content: center;
  }

  .lesson-button {
    // border: none;
    // outline: none;
    // background: transparent;
    // font-size: 20px;
    // text-transform: uppercase;
    margin-top: 0px !important;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
  }

  .cancel-button {
    color: #ff0055;
  }

  .mod-item {
    clear: both;
  }

  .markAsbookedDiv {
    .form-group {
      display: flex !important;
    }

    .label-text {
      padding-bottom: 0 !important;
      font-weight: 500 !important;
    }
  }

  .rec-form {
    .form-group {
      label {
        padding-bottom: 5px;
        font-weight: 600;
        font-size: 13px;
      }
    }
  }

  .form-group,
  .form-group:last-child {
    -webkit-box-flex: 0;

    flex: 0 0 100% !important;
    padding-right: 10px;
    display: block;
    float: left;

    select {
      float: left;
      width: 100%;
    }
  }

  .left-right-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex: 0 0 100%;
    max-width: 100%;

    .left-side,
    .right-side {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;
      padding-left: 0;

      .form-group,
      .form-group:last-child {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        padding-right: 0;
      }

      ._rec-form {
        .form-group {
          padding-right: 10px;
        }
      }
    }

    .lessons-note {
      padding-top: 5px;

      h5 {
        font-weight: 600;
        text-align: left;
        font-size: 13px;
        color: #333;
        line-height: 1;
      }

      textarea {
        width: 100%;
        border-radius: 5px;
      }

      .notification {
        .not-icon {
          left: 100% !important;
        }
      }
    }
  }

  .modal-title {
    h3 {
      font-size: 37px;
      font-weight: 500;
      line-height: 1;
      text-align: left;
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
  }

  .each-col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
  }

  .first-col,
  .second-col,
  .thirth-col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33%;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
  }
}

.p-rel {
  position: relative;
}

.p-abs-refresh {
  position: absolute;
  right: -20px;
  bottom: 0;
}

.messages,
.lessonViewPage {

  input[type="text"],
  input[type="number"],
  input[type="email"],
  select {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    height: 31px;
    padding-left: 4px;
  }
}

.ignore-right-border {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 33px !important;
}

.modal .modal-content .lessons-note .modal-form-group h5 {
  height: 20px;
}

.modal .modal-content .lessons-note {
  margin-top: 10px;
}

.lesson-camp-section {
  margin-top: 15px;

  .alert-box {
    margin-bottom: 5px !important;
  }
}

.camps-section {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  .alert-info {
    text-align: left;
  }

  .purple {
    border: 1px solid purple;
    color: purple;
    background-color: #ffffff;
  }

  .orange {
    border: 1px solid orange;
    color: orange;
    background-color: #ffffff;
  }

  .camps-section-option {
    border-top: 1px solid #ccc;
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-top: 5px;
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;

    .counter-camp {
      margin-right: 4px;
    }

    .form-group {
      display: flex !important;
      justify-content: space-between;
      align-items: baseline;
      flex-direction: row-reverse;
      margin-top: 10px;
      margin-bottom: 10px;
      flex: auto;

      label {
        text-align: left;
      }

      input[type="checkbox"] {
        position: static;
        right: 0;
        display: flex;
        margin-right: 5px;
      }
    }
  }
}

.allow-booking-until {
  flex-direction: column;
  background: rgba(154, 211, 8, 0.1);
  border-radius: 5px;
  padding: 10px;
  text-align: center !important;

  .switch-booking-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  label {
    text-align: center;
  }

  .allow-booking-until-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .lesson-form-group {
      width: 100%;
      padding-top: 0;
    }
  }
}
