.buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  .each:last-child {
    margin-left: 15px;
    position: relative;
  }
}
