.racquets-page {
  .header-section {
    position: relative;
  }

  .main-container {
    padding-left: 60px;
    padding-right: 60px;
  }

  .covid-section {
    text-align: left;
    font-size: 18px;
    font-family: Helvetica;
    color: #f08005;
    width: 100%;
  }

  .racquets_top_bg {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 80%;
  }

  .main-container-inner {
    display: flex;
    align-items: end;
    justify-content: space-between;

    margin-top: 45px;
  }

  .title-business {
    h2 {
      font-family: Helvetica;
      font-size: 30px;
      opacity: 0.84;
      color: #292a34;
      letter-spacing: 0.71px;
      text-align: center;
      line-height: 64px;
    }
  }

  .left-direction {
    flex-direction: left;

    .img-part {
      right: 0;
      left: auto !important;
    }

    .desc-part {
      margin-right: 55px;
      margin-left: initial !important;
    }
  }

  .each-section {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: relative;
    margin-bottom: 40px;

    .container-inner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .img-part {
      width: 518px;

      .social_image {
        width: 50%;
        display: block;
        margin: auto;
      }
    }

    .text_content {
      h1 {
        font-weight: 700;
        font-family: "Noto Sans";
        font-size: 35px;
        color: #13150d;
        line-height: 52px;
      }

      p {
        font-weight: 400;
        font-family: "Noto Sans";
        font-size: 18px;
        color: #13150d;
        font-style: normal;
        width: 93%;

        .orange_font {
          color: #f68536 !important;
        }
      }
    }

    .schedule_demo_cutom {
      .button_c {
        margin: auto;
        display: block;
        border-style: none;
        border-radius: 100px;
        z-index: 129;
        width: 233px;
        height: 58px;
        background: #9ad308;
        box-shadow: none;
        text-shadow: none;
        color: #fff;
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
        font-family: Noto Sans;
        font-style: normal;
        text-align: center;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-top: 3%;
      }
    }

    .covid-desc-part {
      p {
        color: #f68536 !important;
      }

      font-size: 18px;
      color: #f68536;
      margin-left: 75px !important;
    }

    .desc-part {
      width: 514px;
      margin-left: 55px;

      ul {
        width: 300px;
        margin-left: 50px;
      }

      .bubble {
        margin-left: 75px;
      }

      .double-bubble {
        margin-left: 25px;
        margin-top: 25px;
      }

      .title-business {
        margin-bottom: 25px;

        h2 {
          text-align: left;
          line-height: 33px;
          font-size: 30px;

          font-weight: 600;
        }
      }

      .desc {
        p {
          font-family: Helvetica;
          font-size: 18px;
          color: #4f5059;
          letter-spacing: 0;
          line-height: 27px;
        }
      }
    }
  }

  .margin_top {
    margin-top: 5%;
  }

  .demo-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 75px;

    .demo-footer-bg {
      width: 882px;

      img {
        max-width: 100%;
      }
    }

    .schedule-demo-container {
      position: absolute;
      right: 40px;
    }

    /* margin-top: 90px;
      background: url(/assets/img/demo-footer.jpg) no-repeat center center;
      background-size: cover;
      margin-top: 90px;
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
      height: 960px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;*/

    .schedule-demo-container {
      margin-right: 0;
    }
  }

  .video {
    cursor: pointer;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
    box-shadow: 0 0 4px 0 rgba(77, 77, 77, 0.25);

    img {
      max-width: 100%;
    }
  }

  .video-part {
    .title-business {
      margin-top: 112px;
      margin-bottom: 47px;
    }

    .main-container {
      padding-right: 100px;
      padding-left: 100px;
      margin: auto;
    }
  }

  .purple-balls-container {
    width: 658px;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .how-we-defer-from-competitors {
    margin-top: 98px;

    .title-business {
      margin-top: 100px;
    }

    .difers {
      .learn-why-ballbutton {
        margin-top: 20px;
        font-size: 19px;

        a {
          color: #9ad308;
        }
      }

      .main-container {
        justify-content: space-between;
        align-items: end;
      }

      .difer {
        background: #ffffff;
        box-shadow: 0 0 4px 0 rgba(77, 77, 77, 0.25);
        border-radius: 25px;
        padding: 18px;
        flex: 0 0 24%;
        min-height: 424px;

        .difer-image {
          align-items: center;
          display: flex;
          flex: 0 0 100%;
          justify-content: center;
        }

        .difer-text {
          padding-top: 29px;
          padding-bottom: 29px;
          padding-left: 18px;
          padding-right: 18px;
        }
      }
    }
  }

  .court-listing-booking-circle {
    width: 711px;
  }

  .how-it-works {
    margin-top: 143px;

    img {
      max-width: 100%;
    }

    .title-business {
      margin-bottom: 96px;
    }
  }

  .preffered-choice {
    background-image: linear-gradient(223deg,
        rgba(31, 144, 20, 0.2) 0%,
        rgba(89, 185, 0, 0.1) 100%);
    margin-top: -180px;

    .preffered-choice-container {
      padding-top: 92px;
      display: flex;
      position: relative;
      justify-content: start;
      align-items: end;

      .booking-image {
        img {
          max-width: 100%;
          border-radius: 42px;
        }

        position: relative;
        right: -20px;
        width: 250px;
        bottom: -150px;
      }

      .preffered-choice-desc {
        padding-left: 52px;
        flex: 0 0 40%;

        br {
          height: 10px;
          letter-spacing: 0;
          content: "A" !important;
          display: block !important;
        }
      }

      .imac-img {
        width: 480px;

        img {
          max-width: 100%;
        }
      }
    }

    .title {
      margin-bottom: 12px;

      h2 {
        font-family: Helvetica;
        font-size: 30px;
        color: #27519a;
        letter-spacing: 0.71px;
        line-height: 35px;
      }
    }

    p {
      font-family: Helvetica;
      font-size: 16px;
      color: #27519a;
      letter-spacing: 0;
    }
  }

  .green-learn-more {
    background: url("../../assets/img/right-tick.png") no-repeat right;
    padding-right: 15px;
    font-family: Helvetica;
    font-size: 18px;
    color: #9ad308;
    margin-bottom: 20px;
    cursor: pointer;
    background-position-y: 6px;
  }

  .demo-middle {
    margin-top: 62px;
  }

  .schedule-demo-container {
    position: relative;
    z-index: 9;
  }

  .green-border {
    height: 170px;
    width: 15px;
    background: #9ad308;
    position: absolute;
    left: -15px;
    top: 100px;
  }

  .games-list-container {
    width: 690px;
    padding-top: 45px;
    margin: auto;
    margin-top: 7%;
    height: 400px;

    .title {
      h2 {
        font-family: Helvetica;
        font-size: 36px;
        opacity: 0.84;
        color: #292a34;
        letter-spacing: 0.89px;
        line-height: 64px;
      }
    }

    .games-full-release {
      display: flex;
      align-items: center;

      .full-release {
        margin: auto;
        margin-top: 70px;
        color: #23bbf9;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        line-height: 20px;
      }
    }

    .games {
      /* width: 150px;*/
      margin: 0 auto;
      margin-top: 22px;
      margin-left: 35px;

      .game {
        background: url("../../assets/img/green-game-ball.png") no-repeat left;
        padding-left: 30px;
        font-family: Helvetica;
        font-size: 18px;
        color: #9ad308;
        margin-bottom: 10px;
      }
    }

    .double-bubble {
      position: absolute;
      bottom: 0px;
      left: 300px;
    }
  }
}

.ota-class-container {
  padding-top: 230px;

  .inner-ota-container {
    height: 120px;
    border-radius: 4px;
    background: white;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      align-items: center;
      display: flex;
      font-size: 24px;

      a {
        display: inline-flex;
        padding-left: 15px;

        img {
          width: 130px;
        }
      }
    }
  }
}

@media (max-width: 1230px) {
  .header-section {
    .main-container-inner {
      flex-direction: column-reverse;
    }

    .games-full-release {
      margin-top: 22px;
    }

    .games-list-container {
      padding-top: 15px;

      .double-bubble {
        display: flex;
        align-items: center;
        position: static;
      }

      .games {
        margin-left: auto;
        width: 100%;
        margin-top: 0;
      }
    }

    .tennis-guy img {
      display: block;
      margin: 0 auto;
    }

    .games-list-container,
    .tennis-guy {
      width: 100%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1230px) {
  .booking-image {
    display: none;
  }
}

@media all and (max-width: 992px) {

  .imac-img,
  .each-section .img-part {
    width: 100% !important;
  }

  .preffered-choice-container,
  .racquets-page .each-section .container-inner {
    flex-direction: column;
  }

  .preffered-choice-container {
    .booking-image {
      position: static !important;
      margin: 0 auto;
    }
  }
}

@media (max-width: 768px) {
  img {
    max-width: 100%;
  }

  .racquets-page {
    .video {
      width: 100%;
    }

    .title h2,
    .title-business h2 {
      line-height: 1 !important;
      text-align: center;
    }
  }

  .main-container-inner {
    flex-direction: column;
  }

  .difer {
    margin-bottom: 20px;
  }

  .racquets-page .main-container {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .header-section {
    .main-container-inner {
      flex-direction: column-reverse;
    }

    .games-full-release {
      margin-top: 22px;
    }

    .games-list-container {
      padding-top: 15px;

      .double-bubble {
        display: flex;
        align-items: center;
        position: static;
      }

      .games {
        margin-left: auto;
        width: 95%;
        margin-top: 0;
      }
    }

    .games-list-container,
    .tennis-guy {
      width: 100%;
    }
  }

  .racquets-page .demo-footer .schedule-demo-container {
    position: static;
    right: auto;
  }

  .preffered-choice-desc {
    padding-left: initial !important;
  }

  .left-direction .container-inner {
    flex-direction: column-reverse !important;
  }

  .racquets-page .each-section .desc-part {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 15px;
  }

  .p-top-40 {
    padding-top: 0px;
  }

  .racquets-page .each-section .desc-part .title-business h2 {
    text-align: center;
  }

  .racquets-page .each-section .desc-part .desc p {
    text-align: left;
  }

  .racquets-page .purple-balls-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .racquets-page .video-part .title-business {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .video video {
    height: auto;
  }

  .ota-class-container .inner-ota-container {
    height: auto;

    p {
      font-size: 18px;
      flex-direction: column;
      text-align: center;

      a {
        margin-top: 15px;
      }
    }
  }
}
