/*--------------------
Shared Variables
--------------------*/

$small-screen: 476px;
$grey: #111;
$blue: #5db6e8;
$darkBlue: #282c37;
$red: #e53a40;
$green: #8cd790;
$white: #fff;
$radius: 4px;
$bouncy: cubic-bezier(0.2, 1.3, 0.7, 1);

/*--------------------
General
--------------------*/

// Position form at the center of the page
html,
body {
  font-size: 100%;
  color: $darkBlue;
  font-family: sans-serif;
}

.header-pay {
  z-index: 2;
  transform: translateY(55px);
}

.header-payheader-slide {
  transform: translateY(55px);
  z-index: 2;
}

h1 {
  font-weight: 100;
  font-size: 1.4em;
  display: block;
}

.form-container {
  display: flex;
  // background-color: #EEE;
  justify-content: center;
  align-items: center;
  // height: 100%;
  flex-direction: column;
  // border: 1em solid #fff;
  // box-sizing: border-box;
  // position: relative;

  // @media (max-width: $small-screen) {
  //   border: none;
  // }
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #4c4c4c78;
}

.form-container-com {
  display: flex;
  // background-color: #EEE;
  justify-content: center;
  align-items: center;
  // height: 100%;
  flex-direction: column;
  flex: 0 0 30%;
  // border: 1em solid #fff;
  // box-sizing: border-box;
  // position: relative;

  // @media (max-width: $small-screen) {
  //   border: none;
  // }
}

.cardinfo-wrapper {
  display: flex;
  justify-content: space-around;
}

.bg-illustration {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;

  svg {
    width: 100%;
  }
}

.card-shape {
  border-radius: 6px;
  padding: 4em 2em 1em;

  @media (max-width: $small-screen) {
    padding: 4em 1.5em 1em;
  }
}

// Form wrapper styling
#my-sample-form {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  padding: 8em 3em 2em;
  width: 31em;
  margin-bottom: 2em;
  transition: all 600ms $bouncy;
  animation: cardIntro 500ms $bouncy;
  z-index: 1;
  border-radius: 6px;
  padding: 4em 2em 1em;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  }

  @media (max-width: $small-screen) {
    box-sizing: border-box;
    padding: 7em 2em 2em;
    width: 100%;
  }

  // Change background color based on the card type
  &.visa {
    @extend .card-shape;
  }

  &.master-card {
    @extend .card-shape;
  }

  &.maestro {
    @extend .card-shape;
  }

  &.american-express {
    @extend .card-shape;
  }

  &.discover {
    @extend .card-shape;
  }

  &.unionpay,
  &.jcb,
  &.diners-club {
    @extend .card-shape;
  }
}

.cardinfo-label {
  display: block;
  font-size: 14px;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.cardinfo-exp-date {
  margin-right: 1em;
  width: 100%;
}

.cardinfo-cvv {
  width: 100%;
}

#button-pay {
  cursor: pointer;
  width: 16em;
  font-size: 15px;
  border: 0;
  padding: 1.2em 1em;
  color: #fff;
  background: #282c37;
  border-radius: $radius;
  z-index: 0;
  transform: translateY(-100px);
  transition: all 500ms $bouncy;
  opacity: 0;
  -webkit-appearance: none;

  &:hover {
    background: lighten(#282c37, 20%);
  }

  &:active {
    animation: cardIntro 200ms $bouncy;
  }

  &.show-button {
    transform: translateY(0);
    opacity: 1;
  }
}

// Card type image styles
.cardinfo-card-number {
  position: relative;
}

#card-image {
  position: absolute;
  top: 2em;
  right: 1em;
  width: 44px;
  height: 28px;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/346994/card_sprite.png);
  background-size: 86px 458px;
  border-radius: $radius;
  background-position: -100px 0;
  background-repeat: no-repeat;
  margin-bottom: 1em;

  &.visa {
    background-position: 0 -398px;
  }

  &.master-card {
    background-position: 0 -281px;
  }

  &.american-express {
    background-position: 0 -370px;
  }

  &.discover {
    background-position: 0 -163px;
  }

  &.maestro {
    background-position: 0 -251px;
  }

  &.jcb {
    background-position: 0 -221px;
  }

  &.diners-club {
    background-position: 0 -133px;
  }
}

/*--------------------
Inputs
--------------------*/

// Styling for input wrappers, internal font styles are handled in javascript
.input-wrapper {
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.86);
  height: 2.75em;
  border: 1px solid #eee;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.06);
  padding: 5px 10px;
  margin-bottom: 1em;
}

.cardinfo-card-number,
.cardinfo-exp-date,
.cardinfo-cvv {
  transition: transform 0.3s;
}

// Change styles of the input wrappers using Braintree's provided classes.
// Styles the wrapper of the focused input
.braintree-hosted-fields-focused {
  border-color: $blue;
}

// Styles the wrapper of the invalid input
.braintree-hosted-fields-invalid {
  border-color: $red;
  animation: shake 500ms $bouncy both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

/*--------------------
Animations
--------------------*/

@keyframes cardIntro {
  0% {
    transform: scale(0.8) translate(0, 0);
    opacity: 0;
  }

  100% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}

.promoButtonWraper {
  padding: 15px;
  min-width: 400px;
  background: white;
  border-radius: 5px;

  .StripeElement {
    border: 1px solid #ccc;
    background-color: white;
    outline: none;
    padding: 11px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    font-size: 16px;
  }



  input {
    padding: 7px;
    font-size: 18px;
  }
}
