.modal-cach-input {
  .form-group {
    margin-top: 0 !important;
  }

  .modal-content {
    position: relative;
  }

  .cach-input-contaier {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .each-div {
      flex: 0 0 45%;
    }
  }

  .centered {
    justify-content: center;
  }

}
