.main-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}

.form-group {
  margin-top: 20px;
  position: relative;

  input[type='checkbox'] {
    position: absolute;
    right: 10%;
  }

  input[type='text'],
  input[type='password'],
  input[type='email'],
  textarea,
  input[type='number'] {
    width: 100%;

    &.error {
      border-color: red;
    }
  }

  .char-count {
    position: absolute;
    left: 45%;
    bottom: 100%;
    font-size: 12px;
  }

  textarea {
    width: 100%;
    resize: none;
    height: 120px;

    &.error {
      border-color: red;
    }
  }

  select {
    flex: 0 0 55%;
    height: 31px;
  }

  label {
    &.label-icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      order: 1;
    }
  }
}

.app:not(.app-bootstrap-support) {
  .form-group {

    input[type='text'],
    input[type='password'],
    input[type='email'],
    textarea,
    input[type='number'] {
      border: none;
      border: 1px solid #606948;
      background-color: transparent;
      font-size: 20px;
      outline: none;
      padding: 5px;
      border-radius: 3px;
      font-size: 16px;
    }

    textarea {
      border: 1px solid #606948;
      background-color: transparent;
      font-size: 16px;
      outline: none;
      padding: 5px;
      border-radius: 5px;
      padding-right: 20px;
    }

    select {
      border: 1px solid #606948;
      background-color: transparent;
      font-size: 16px;
      outline: none;
      padding: 5px;
      border-radius: 5px;
    }

    label {
      &.label-icon {
        border: 1px solid #606948;
        border-radius: 50%;
        cursor: pointer;
        position: relative;

        &:after {
          content: '';
          background-image: url('../../img/pricing-check.png');
          position: absolute;
          top: -3px;
          left: 3px;
          width: 100%;
          height: 100%;
          opacity: 0;
          background-size: cover;
          background-position: center;
        }
      }
    }

    input[type='checkbox'] {
      &:checked~label {
        &.label-icon {
          &:after {
            opacity: 1;
          }
        }
      }
    }

    input[type='radio'] {
      &:checked~label {
        &.label-icon {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

// .app:not(.app-bootstrap-support) {
.container {
  width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.no-shadow {
  box-shadow: none !important;
}

.new-design-button {
  background: #9ad308;
  border-radius: 20px;
  font-family: Helvetica;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.36px;
  text-align: center;
  min-width: 110px;
  border: 1px solid #9ad308;
  padding: 5px 25px;
  cursor: pointer;
  outline: none;
  height: 40px;
  font-weight: 500;
}

.app-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#loaderModal {
  position: relative;
  left: 36%;
  top: 50%;
  z-index: 1;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.backblack {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #4c4c4c78;
}

.not-found {
  height: 100vh;
 // background-color: #9ad308;
 background-image: linear-gradient(
  180deg,rgba(90,186,13,.53),rgba(169,211,135,.57) 21%,rgba(237,247,229,.88) 60%,#d3e7c4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 25px;

  div{
    a{
      text-decoration: none;
      color: #fff;
      font-size: 22px;
      font-weight: bold;
      width: 65%;
      height: 47px;
    line-height: 2.2;
    }
  }
  img{
    width: 20%;
    margin-right: 7%;
  }
}

.check-group {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;

  &.pt0 {
    padding-top: 0;
  }

  .full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  input {
    position: absolute;
    visibility: hidden;
  }

  label.label-text {
    order: 2;
  }

  .form-group {
    flex: 0 0 50%;
  }

  &.access {
    display: flex;
    //justify-content: flex-end;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    justify-content: space-between;

    .radio-label {
      flex: 0 0 15%;

      h4 {
        border: none;
        padding-top: 0;
      }
    }

    .form-group {
      flex: 0 0 27.5%;
      margin-top: 0;

      label.label-text {
        flex: 0 0 80%;
      }

      .notification {
        span.not-icon {
          left: 102%;
        }
      }
    }

    &.not-icon {
      left: 106%;
    }

    .access-code {
      flex: 0 0 27%;
      padding-left: 30px;
      position: relative;

      input {
        position: static;
        visibility: visible;
      }

      label {
        font-size: 10px;
      }

      .notification {
        span {
          width: 12px;
          height: 12px;
          font-size: 10px;
          right: 60px;
          top: 6px;
          margin: 0;

          img {
            width: 12px;
            height: 12px;
          }
        }

        p {
          margin-left: -82px;
        }
      }
    }
  }

  &.column {
    .form-group {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;

      label.label-text {
        flex: 0 0 auto;
      }
    }
  }

  &.line {
    .form-group {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.search {
  padding: 15px 5px;
  margin-top: 10px;
  border-top: 1px solid black;
  display: flex;

  .react-datepicker__input-container {
    position: static;
    display: initial;
  }

  .react-datepicker-wrapper {
    display: initial;
  }

  input[type='text'] {
    width: 100%;
    border: 1px solid #606948;
    border-radius: 3px;
  }

  input {
    margin: 0 40px 0 0;
    font-size: 18px;
    border-radius: 6px;
    padding: 4px;
  }
}

.address-group {
  position: relative;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 998;
  right: 0;
  width: 100%;
  top: 100%;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
}

.data-of-birth {
  display: flex;
  justify-content: space-between;

  select {
    display: block;
    flex: auto;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.notification {
  .notificationFromBottom{
    top:20px !important;
    bottom: auto !important;

    &:after{
      top: -17px !important;
      transform: rotate(180deg);
    }
  }
  .notificationFromBottomAvalibility{
    top:34px !important;
    left: 5px !important;
    bottom: auto !important;

    &:after{
      top: -17px !important;
      left: 0px !important;
      transform: rotate(180deg);
    }
  }
  p {
    position: absolute;
    left: 100%;
    bottom: 140%;
    width: 200px;
    padding: 5px;
    background-color: #000000;
    border-radius: 3px;
    color: #ffffff;
    margin-bottom: 0px;
    margin-left: -5px;
    opacity: 0;
    visibility: hidden;
    z-index: 555;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 7px;
      width: 0;
      height: 0;
      border: 10px solid #000000;
      border-color: #000000 transparent transparent;
    }
  }

  span {
    &.not-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 101%;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(180deg);
      width: 16px;
      height: 16px;

      img {
        width: 16px;
        height: 16px;
      }

      cursor: pointer;

      &:hover {
        ~p {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &.right {
    span {
      &.not-icon {
        left: auto;
        right: 15px;
      }
    }

    p {
      margin-left: -40px;
    }
  }

  &.xz-fix {
    p {
      bottom: 80%;
    }
  }
}

.check-group {
  .notification {
    p {
      margin-bottom: 20px;
    }
  }
}

.loader {
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid #555;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  animation: spin 2s linear infinite;
}

.loader-smole {
  border: 1px solid #f3f3f3;
  /* Light grey */
  border-top: 1px solid #555;
  /* Blue */
  border-radius: 50%;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  animation: spin 2s linear infinite;
}

.react-datepicker__day--today {
  color: #ff0000 !important;
}

.react-datepicker__day--selected {
  color: #ffffff !important;
  background-color: #9ad308 !important;
}

.react-datepicker__day--keyboard-selected {
  color: #ffffff !important;
  background-color: #9ad308 !important;
}

text.recharts-text.recharts-label {
  font-size: 12px;
}

.privacy,
.terms {
  padding: 30px 0;

  h1 {
    padding-bottom: 20px;
    font-weight: bold;
    text-align: center;
  }

  h2 {
    text-align: center;
    padding-bottom: 20px;
  }

  h3 {
    padding-bottom: 20px;
    font-weight: bold;
  }

  p {
    padding-bottom: 20px;
  }

  ul {
    padding-left: 20px;

    li {
      padding-bottom: 20px;
    }

    &.info {
      padding-left: 0;

      li {
        list-style: none;
        padding-bottom: 0;
      }
    }
  }

  ol {
    counter-reset: item;
    padding-left: 20px;

    li {
      display: block;
      position: relative;
      padding-bottom: 20px;

      &:first-child {
        margin-top: 20px;
      }

      &:before {
        content: counters(item, '.') ' ';
        counter-increment: item;
        position: absolute;
        right: 100%;
        margin-right: 10px;
        top: 0;
      }

      ol {
        padding-left: 30px;

        li.info {
          p {
            padding-bottom: 0;
          }
        }

        ol {
          counter-increment: none;
          padding-left: 20px;

          li {
            display: list-item;
            list-style-type: lower-alpha;

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  span {
    font-weight: bold;
  }
}

.rel {
  position: relative;
}

.charge-history {
  position: relative;
}

.switcher-container {
  display: flex;
  margin-top: 20px;
  position: relative;
  line-height: 35px;

  p {
    flex: 0 0 44%;
    font-weight: bold;
  }
}

.picky-container {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 55%;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;

  div.error {
    border-color: red;

    .picky__input {
      border: 1px solid red;
    }
  }
}

.refreshButton {
  width: 30px;
  height: 30px;
  display: inline-block;
  cursor: pointer;
  vertical-align: top;
}

.midleSpan {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
  padding-right: 10px;
  font-size: 14px;
}

.updateTypeFormat .form-group select {
  width: 100%;
}

.booked-persons {
  .sign-up-btn {
    text-align: right;
    padding: 0;
  }
}

.booked-persons {
  margin-top: 20px;
}

.form-enlarge-container .plus-icon {
  width: 30px !important;
  display: inline-block;
}

.autofill-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: baseline;
  align-items: flex-start;
  flex-direction: row;

  .input-drop {
    li {
      text-align: left !important;
    }
  }

  .input-drop {
    display: flex;
    flex: 0 0 100%;
    flex-direction: row;
    max-height: 195px;
    overflow-y: auto;

    ul {
      li {
        cursor: pointer;
        list-style: none;
        padding: 8px;
        border-bottom: 1px solid #dddddd;
      }
    }
  }

  .grouped-form {
    display: flex;
    margin-left: 5px;

    .sign-up-btn {
      padding: 0 !important;
    }

    .form-group {
      padding-right: 0 !important;

      &:first-child {
        flex: 0 0 100% !important;
        padding-right: 10px;
      }
    }
  }
  .grouped-formNBU {
    display: flex;
    width: 100%;
    margin-left: 5px;

    .sign-up-btn {
      padding: 0 !important;
    }

    .form-group {
      padding-right: 0 !important;

      &:first-child {
        flex: 0 0 100% !important;
        padding-right: 10px;
      }
    }
  }
}

.ignore-right-border {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ignore-right-borderUBU {
  margin-bottom: 3px;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.d-none {
  display: none !important;
}

.booked-persons {
  .sign-up-btn {
    button {
      margin-right: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.form-enlarge-container {
  .rec-form {
    .form-group {
      input[type='text']:disabled {
        width: 80%;
      }
    }
  }
}

.lessonViewPage {
  .container {
    width: 1300px;
  }

  .charge-col:last-child {
    flex: 0 0 21%;
  }

  .booked-by-section {
    .form-group {
      margin-top: 0;
      display: flex;
    }

    .rec-form {
      position: relative;
    }

    .sign-up-btn {
      padding: 0;
    }
    .sign-up-btnNBU {
      width: 100%;
      padding: 0;
    }

    .filters-container {
      .from-to {
        flex: 0 0 35%;
        margin-right: 5px;
      }

      .buton-save {
        flex: 0 0 30%;
      }
    }

    .sign-up-btn button {
      border-radius: 0;
      margin-right: 5px;
      min-width: auto;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 16px;
      font-weight: 400;
      padding-top: 7px;
      padding-bottom: 8px;
    }
  }

  .form-enlarge-container {
    position: relative;

    .lessons-quantity {
      margin-top: 15px;
    }

    .form-group {
      margin-top: 0;
      display: flex;

      &:first-child {
        flex: 0 0 60%;
        padding-right: 10px;
      }
    }

    .plus-icon {
      position: static;
      right: 0;
      left: 0;
      bottom: 10px;

      button,
      a {
        color: #ffffff;
        background-color: #9ad308;
        width: 30px;
        height: 30px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        font-weight: bold;
        cursor: pointer;
        border: none;
        border-radius: 50%;
        margin-left: auto;
        line-height: 0.8;

        img {
          max-width: 20px;
        }
      }
    }

    .modal-form {
      padding: 0;
    }

    .modal-title {
      h3 {
        font-weight: bold;
        margin-top: 10px;
        text-align: center;
        font-size: 20px;
        margin-bottom: 45px;
      }
    }

    .modal-form-group textarea {
      width: 100%;
      height: 100px;
      resize: none;
      border: 1px solid #606948;
      background-color: transparent;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      padding: 5px;
      padding-right: 25px;
    }

    .lessons-note .modal-form-group .notification span {
      margin-left: -25px;
    }

    .modal-form-group {
      position: relative;

      .char-count {
        font-size: 10px;
        float: right;
        display: block;
      }

      .char-count+div::after {
        content: "";
        display: block;
        clear: both;
      }

      textarea {
        width: 100%;
        height: 100px;
        resize: none;
        border: 1px solid #606948;
        background-color: transparent;
        border-radius: 3px;
        padding: 5px;
        padding-right: 25px;

        &.error {
          border-color: #ff0000;
        }
      }

      .notification {
        span {
          top: 30px;
          bottom: auto;
          margin-left: -35px;
        }

        p {
          margin-left: -30px;
          margin-bottom: -10px;
        }
      }
    }

    .dash-modal-btn {
      padding-bottom: 0;

      ul {
        margin: 0;

        li {
          padding: 0;
        }
      }
    }
  }
}

.detailsWrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 10px -15px;

  .form-enlarge-container {
    /* -webkit-box-flex: 0;
    -webkit-flex: 0 0 34%;
    -ms-flex: 0 0 34%;
    flex: 0 0 33%;*/
    padding: 0 15px;

    .lessonTypeClass label {
      width: 100%;
    }

    .form-group label {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .switcherLabel {
      width: 100%;
    }
  }

  .charge-wrapp {
    flex: 0 0 22% !important;
    margin-left: 0px;
  }

  .booked-by-section {
    flex: 0 0 45% !important;
    max-width: 45% !important;
    border-left: 1px solid #09b1e7;
    border-right: 1px solid #09b1e7;
  }
}

.lessons-quantity {
  div.form-group {
    padding: 0 !important;
  }
}

.chargeButton {
  width: 32px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
}

.w-29 {
  width: 29px;
}

.w-35 {
  width: 35px;
}

.view-plan {

  td,
  th {
    max-width: 250px;
  }
}

.w-25 {
  width: 25px;
  height: 25px;
}

.grey-star {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.w-75 {
  width: 65%;
}
.w-40{
  width: 40%;
}
.make-it-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailsWrap {
  position: relative;

  .operation-buttons {
    width: 100%;
    text-align: left;
    display: flex;
  }
}

.history-list {
  li ul li {
    /*word-break: break-all;*/
  }

  .deleteButton {
    padding-top: 0;
    margin-left: 0;
    text-align: left !important;
  }
}

.pastLesson:after {
  width: 100%;
  height: 100%;
  content: '';
  opacity: 0.4;
  position: absolute;
  background: white;
  z-index: 555;
}

.pastHourClass {
  margin-left: 30px;

  h2 {
    padding-top: 7px;
    color: #d75a4a !important;
  }
}

.lessonTypeClass {
  h2 {
    padding-top: 7px;
  }
}

li.terminal-list-item {
  list-style-type: none;
  padding: 4px;
  font-size: 18px;
  border-bottom: 1px solid black;
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 5px;
}

li.subscription-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3%;
  border-radius: 5px;
  //transform: translate(0,0) !important;

  .operations {
    display: flex;
    
    
  }
}
.moveBtn{
  width: 20px !important;
  height: 20px !important;
  float: inherit !important;
  display: block;
  position:unset !important;
 
 
}

li.terminal-list-item:hover {
  background-color: #e1e1e1e1;
}

.addReaderClass {
  display: inline-block;
  padding: 6px 6px;
  font-size: 18px;
  color: #ffffff;
  background-color: #9ad308;
  border: none;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  min-width: 90px;
  text-align: center;
  margin-top: 10px;
}

.arrow-left-back {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.sendEmail {
  width: 35px;
}

.w-100-block {
  width: 100%;
  display: inline-block;
}

.disableAction {
  opacity: 0.5;
}

.chargeIcon.loading:after {
  content: '';
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  padding-right: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #333;
  -webkit-animation: spinner 0.6s linear infinite;
  animation: spinner 0.6s linear infinite;
  left: 22px;
}

.chargeIcon {
  position: relative;
}

.tax-container {
  .form-half-group {
    width: 50% !important;
  }

  .suggestion-item--active,
  .suggestion-item {
    text-align: left;
  }

  .form-group {
    label {
      display: inline-block;
      margin-bottom: 0.5rem;
      text-align: left;
      width: 100%;
      font-weight: 600;
    }

    .form-custom-control {
      display: block;
      width: 100% !important;
      padding: 0.375rem 0.25rem;
      font-size: 1rem;
      line-height: 1.3;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #606948;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

  .flex-group {
    display: flex;
    flex-wrap: wrap;

    label {
      flex: 0 0 100%;
      padding-left: 30px;
    }

    .field-flex-wrapper {
      flex: 0 0 40%;
      margin-left: 0;

      input {
        width: 100% !important;
        padding-left: 5px;
      }
    }
  }

  .m-left4 {
    margin-left: 4% !important;
  }
}

.disableAction::after {
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 555;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0.5;
  cursor: no-drop;
}

.m-bottom-no {
  margin-bottom: 0 !important;
}

.pad-left-right-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.make-bolder {
  font-weight: 600;
}

.m-bottom-15 {
  margin-bottom: 15px;
}

.m-bottom-40 {
  margin-bottom: 40px;
}

.tax-container {
  .taxtTotal {
    text-align: left;
  }

  .taxRateInfo {
    text-align: right;
    padding-bottom: 0px;
  }
}

.deleteButton {
  margin-left: 15px;

  button {
    background-color: #ff605c !important;
  }
}

.addLessonButton {
  margin-left: 15px;

  button {
    background-color: #ffbd44 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.taxrateClass {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}

.class-title {
  font-weight: 600;
  text-align: center;
}

.notification-group {
  position: relative;

  label {
    padding-left: 30px;
  }

  .notification {
    p {
      left: 0;
      background-color: white;
      color: black;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      font-weight: 400;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 7px;
        width: 0;
        height: 0;
        border: 10px solid white;
        border-color: white transparent transparent;
      }
    }

    span.not-icon {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: absolute;
      left: 1%;
      top: -45px;
      bottom: 0;
      margin: auto;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .not-icon {
    left: 0;
    margin-top: 0;
  }
}

.notification-for-subtotal {
  display: inline-block;

  .not-icon {
    top: 0 !important;
  }
}

._notification-for-subtotal {
  display: inline-block;
  padding-left: 30px;

  .not-icon {
    top: 0 !important;
  }
}

table.r-responsive-table th,
table.r-responsive-table td {
  word-break: break-word !important;

}

table.r-responsive-table th,
table.r-responsive-table td {
  border: 1px solid #ddd;
}

.pretty-button {
  margin-bottom: 5px;

  .notification {
    .not-icon {
      margin-left: 5px;
    }
  }

  button[class*='-button'] {
    background: #1aab8a;
    color: #fff;
    border: none;
    position: relative;
    height: 30px;
    font-size: 11px;
    cursor: pointer;
    transition: 800ms ease all;
    outline: none;
    width: 100%;
    min-width: 80px;
  }

  button[class*='-button']:hover {
    background: #fff;
    color: #1aab8a;
  }

  button[class*='-button']:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background: #fff;
    transition: 400ms ease all;
  }

  button[class*='-button']:after {
    right: inherit;
    top: inherit;
    left: 0;
    bottom: 0;
  }

  button[class*='-button']:hover:before,
  button[class*='-button']:hover:after {
    width: 100%;
    transition: 600ms ease all;
  }
}

.h-30 {
  height: 30px !important;
}

.th-with-notification {
  position: relative;
  padding-right: 25px !important;

  .notification {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.green-button {
  background: #9bd129 !important;

  &:hover {
    background: #fff !important;
    color: #9bd129 !important;
  }

  &:before,
  &:after {
    background: #9bd129 !important;
  }
}

.green-see-button {
  background: #2e8b57 !important;

  &:hover {
    background: #fff !important;
    color: #2e8b57 !important;
  }

  &:before,
  &:after {
    background: #2e8b57 !important;
  }
}

.green-soap-button {
  background: #8cdd81 !important;

  &:hover {
    background: #fff !important;
    color: #8cdd81 !important;
  }

  &:before,
  &:after {
    background: #8cdd81 !important;
  }
}

.pink-button {
  background: #e9529f !important;

  &:hover {
    background: #fff !important;
    color: #e9529f !important;
  }

  &:before,
  &:after {
    background: #e9529f !important;
  }
}

.green-dark-button {
  background: #006400 !important;

  &:hover {
    background: #fff !important;
    color: #006400 !important;
  }

  &:before,
  &:after {
    background: #006400 !important;
  }
}

.green-lime-button {
  background: #32cd32 !important;

  &:hover {
    background: #fff !important;
    color: #32cd32 !important;
  }

  &:before,
  &:after {
    background: #32cd32 !important;
  }
}

.green-olive-button {
  background: #6b8e23 !important;

  &:hover {
    background: #fff !important;
    color: #6b8e23 !important;
  }

  &:before,
  &:after {
    background: #6b8e23 !important;
  }
}

.blue-diamond-button {
  background: #0ebfe9 !important;

  &:hover {
    background: #fff !important;
    color: #0ebfe9 !important;
  }

  &:before,
  &:after {
    background: #0ebfe9 !important;
  }
}

.lightskyblue-button {
  background: #87cefa !important;

  &:hover {
    background: #fff !important;
    color: #87cefa !important;
  }

  &:before,
  &:after {
    background: #87cefa !important;
  }
}

.blue-topaz-button {
  background: #0198e1 !important;

  &:hover {
    background: #fff !important;
    color: #0198e1 !important;
  }

  &:before,
  &:after {
    background: #0198e1 !important;
  }
}

.brown-button {
  background: #b34346 !important;

  &:hover {
    background: #fff !important;
    color: #b34346 !important;
  }

  &:before,
  &:after {
    background: #b34346 !important;
  }
}

.shamrock-button {
  background: #56a882 !important;

  &:hover {
    background: #fff !important;
    color: #56a882 !important;
  }

  &:before,
  &:after {
    background: #56a882 !important;
  }
}

.red-bg {
  background: #ff605c !important;
}

.red-button {
  background: #ff605c !important;

  &:hover {
    background: #fff !important;
    color: #ff605c !important;
  }

  &:before,
  &:after {
    background: #ff605c !important;
  }
}

.light-blue-button {
  background: #50d4f3 !important;

  &:hover {
    background: #fff !important;
    color: #50d4f3 !important;
  }

  &:before,
  &:after {
    background: #50d4f3 !important;
  }
}

.yellow-button {
  background: #ffbd44 !important;

  &:hover {
    background: #fff !important;
    color: #ffbd44 !important;
  }

  &:before,
  &:after {
    background: #ffbd44 !important;
  }
}

.orange-button {
  background: orange !important;

  &:hover {
    background: #fff !important;
    color: orange !important;
  }

  &:before,
  &:after {
    background: orange !important;
  }
}

.purple-button {
  background: purple !important;

  &:hover {
    background: #fff !important;
    color: purple !important;
  }

  &:before,
  &:after {
    background: purple !important;
  }
}
.purple-button_new {
  background: #24ad24 !important;

  &:hover {
    background: #fff !important;
    color: #24ad24 !important;
  }

  &:before,
  &:after {
    background: #24ad24 !important;
  }
}

.blue-button {
  background: #077ddf !important;

  &:hover {
    background: #fff !important;
    color: #077ddf !important;
  }

  &:before,
  &:after {
    background: #077ddf !important;
  }
}

.blue-button_light {
  background: #6dd0fd !important;

  &:hover {
    background: #fff !important;
    color: #6dd0fd !important;
  }

  &:before,
  &:after {
    background: #6dd0fd !important;
  }
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.picky-container-20 {
  flex: 0 0 20%;
}

.input-with {
  flex: 0 0 65%;
  max-width: 65%;

  div[class*='-indicatorContainer'] {
    margin-top: -5px;
  }

  div[class*='-singleValue'],
  div[class*='-placeholder'] {
    margin-top: -4px;
    color: #282c37;
  }

  div[class*='-control'] {
    border: 1px solid #63626f;
    height: 31px;
    min-height: auto !important;

    div:first-child {
      div[class*='-Input'] {
        height: 31px;
      }
    }
  }

  span[class*='-indicatorSeparator'] {
    display: none;
  }

  input[type='text'] {
    max-width: 100% !important;
    height: auto !important;
  }
}

.input-with-extra {
  flex: 0 0 100%;
  max-width: 100%;
}

.plus-icon {
  position: sticky;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0px;

  button,
  a {
    color: #ffffff;
    background-color: #9ad308;
    width: 25px;
    height: 25px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    margin-left: auto;
    line-height: 1;
    margin-top: -3px;

    img {
      max-width: 20px;
    }
  }
}

.btn-extra {
  margin-top: 15px;
  font-size: 20px !important;
  padding: 3px 14px;
}

.btn-extra_color {
  border: none;
  box-shadow: 0 0 1px 0px #000;
  border-radius: 2px;
}

.lessonType label {
  text-align: left;
  display: block;
}

.modal-content .planModal {
  .facility_groups-select {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.lesson-item-more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: scale(0.8);

  button{
    margin-left: 3px;
  }
}

.dates-table {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  .lesson-form-group {
    flex-direction: inherit;
    padding-top: 0;
  }
}

.addMultipleButton {
  vertical-align: middle;
  display: table-cell;
  line-height: 30px;
  font-size: 17px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.style-for-plus-icon .plus-icon {
  float: right !important;
  margin-left: 10px !important;
}

.disabled-div {
  position: relative;
}

.disabled-tr {
  cursor: none;
  opacity: 0.5;
}

tr[disabled] {
  td {
    position: relative;

    padding: 8px 20px;
  }
}

tr[disabled] td::after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
}

.disabled-div::after {
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
  top: 0;
  content: '';
  opacity: 0.5;
  left: 0;
  position: absolute;
}

.ignore-right-border {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 33px;
}

._rec-form {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.subsc-container {
  margin-top: 15px;

  .modal-content {}

  select {
    width: 100%;
    height: 31px;
  }

  .subscription-item {
    img {
      float: right;
      display: inline-block;
      margin-top: -2px;
    }
  }
}

.email-content {
  width: 400px;
}

.partial-refund {


  label {
    padding-left: 0px;
  }


}

.login-form {
  .check-group {
    .form-group {
      flex: 0 0 100% !important;
      flex-direction: row-reverse !important;

      .label-text {
        flex: 1 0 90% !important;
      }
    }
  }
}

.form-group label.error {
  border-color: red;
}

.minimalistBlack {
  td {
    .notification {

      .not-icon,
      p {
        left: 90%;
      }
    }
  }
}

.m-r-20 {
  margin-right: 20px;
}

.in-flex {
  display: inline-flex;
}

.homepage {
  .info-section {
    padding-bottom: 40px;
    padding-top: 40px;

    .message-section {
      display: flex;

      .text-section {
        flex: 0 0 50%;
        padding-right: 40px;
      }

      .text-section-right {
        flex: 0 0 50%;
        padding-left: 40px;
      }

      .img-section {
        flex: 0 0 50%;

        img {
          max-width: 100%;
        }
      }
    }

    p,
    .ul-messagesection {
      font-family: arial;
      text-align: left;
      font-size: 20px;
    }

    span {
      width: 100%;
      display: block;
      text-align: left;
      font-size: 23px;
      color: #1bb1e4;
      font-family: arial;
    }
  }
}

.mac-img {
  p {
    span {
      text-align: left;
      color: #1bb1e4;
      font-family: arial;
    }
  }
}

label[for='agree'] {
  a {
    color: #9ad308;
  }
}

.analytics {
  .container {
    max-width: 1400px;
    width: 100%;
  }

  .midleSpan {
    margin-bottom: 5px;
  }
}

.dashboradswitcher {
  label {
    span {
      background-color: #c7e588;

      &::before {
        background-color: #9bd12a;
      }
    }
  }
}

div[class^='kn-search'] {
  input {
    border: none !important;
  }
}

div[class^='kn-search__icon'] {
  display: none;
}

.pretty-button {
  button {
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.ul-messagesection {
  margin-left: 25px;
}

.mac-img .message-section {
  font-size: 20px;
}

.react-contextmenu-item {
  a {
    font-size: inherit !important;
    color: #373a3c !important;

    &:hover {
      text-decoration: none !important;
      color: white !important;
    }
  }
}

select[name='couchFilter'] {
  height: 31px;
  width: 70%;
  font-size: 16px;
}

.lessons-dataPicker {
  select[name='familyFilter'] {
    width: 72%;
  }
}

select[name='familyFilter'] {
  height: 31px;
  width: 100%;
  font-size: 16px;
}

.alert-box {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 5px;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;

  span{
    font-size: 14px !important;
  }
}

.alert-warning {
  a {
    color: #856404;
  }

  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  white-space: nowrap;
}

.min_list_duration {
  .midleSpan {
    font-weight: bold;
  }
}

.messages .subsc-container .plus-icon button {
  margin-left: 0;
}

.m-top-15 {
  margin-top: 15px;
}

.m-top-0 {
  margin-top: 0px !important;
}

.subscription-btn-comp,
.showgroups-btn {

  th,
  td {
    text-align: left !important;
  }
}

.m-top-40 {
  margin-top: 40px;
}

.p-top-40 {
  padding-top: 40px;
}

.f-left-add {
  float: right;
  margin-top: 2px;
  margin-left: 5px;
}

.rules-applied {
  span {
    font-size: 14px;
    padding: 5px 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    text-decoration: underline;
    cursor: pointer;
  }
}

.plus-container {
  display: flex;

  .plus-icon:first-child {
    margin-left: 0;
  }

  .plus-icon:last-child {
    margin-left: auto;
    margin-right: 0;
  }
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-center {
  justify-content: center !important;
}

.lessons-list {
  .table thead th {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    min-width: 100px;

    &:last-child {
      min-width: 50px;
    }
  }

  .flex-container {
    .plus-icon {
      margin-left: auto;
    }
  }

  .filter-container {
    margin-top: 10px;
    margin-bottom: 20px;

    .filters {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .each-filter {
        flex: 0 0 40%;
      }

      .switcherLabel {
        font-weight: 600;
        line-height: 1.5;
        font-size: 15px !important;
      }
    }
  }
}

.type-container {
  display: flex;
}

.m-left-15 {
  margin-left: 15px;
}

.m-right-15 {
  margin-right: 15px;
}

.court-not-found {
  font-size: 20px;
  text-align: center;

  a {
    color: #1bb1e4;
  }
}

.filters-container,
.total-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.autofill-container {
  .filters-container {
    margin-left: 5px;
    margin-top: 15px;
    align-items: flex-end;
  }
}

.filters-container {
  .each-filter {
    flex: 0 0 33%;
  }
}

.total-container {
  div:first-child {
    flex: 0 0 50%;
  }

  font-size: 14px;
  font-weight: 600;
}

.bulk-container {
  display: flex;

  form {
    flex: 0 0 100%;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-top: 8px !important;
  }

  label,h5 {
    text-align: left;
    font-size: 14px;
  }
}

.dashboradswitcher {
  display: flex;
  flex-direction: row;

  label {
    flex: 0 0 45px !important;
  }

  .switcher-title {
    cursor: pointer;
    text-align: left;
    line-height: 35px;
  }
}

.switcher-title-div {
  .form-group {
    width: auto;
    display: flex;
  }

  .dashboradswitcher {
    margin: auto;
    flex: 0 0 30% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.lesson-in-court {
  width: 100%;
  max-width: 80% !important;
  text-align: left !important;
}

.lesson-in-court-modal {
  width: 100%;
  max-width: 50% !important;
  text-align: left !important;
}

.react-tabs__tab-panel--selected {
  min-height: 500px;
}

.flex-col-100 {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-around;
}

.analytics {
  .charge-wrapp {
    .charge-col {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0 5px;
    }
  }
}

.flex-col-50 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 5px;
  display: flex;
  align-items: center;

  input[type='text'],
  input[type='number'] {
    width: 100%;
    margin-right: 25px;
  }

  .picky-container-20 {
    flex: 0 0 100%;
  }
}

.flex-col-25 {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 5px;
  display: flex;
  align-items: center;

  input[type='text'],
  input[type='number'] {
    width: 100%;
    margin-right: 25px;
  }

  .picky-container-20 {
    flex: 0 0 100%;
  }
}

.flex-col-40 {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 0 5px;
  display: flex;
  align-items: center;
}
.flex-col-45{
  .form-group{
    max-width: 200px;
  }
}
.autofill- {
  display: flex;
  align-items: start;
  min-height: 31px;

  .plus-icon {
    margin-left: 5px !important;
    margin-top: 3px;
  }
}

.plans-table {
  width: 100%;

  .first-col {
    width: 180px;
  }

  tr[disabled] td {
    padding: 0.45rem;
  }

  .second-col {
    width: 180px;
  }

  .switcher-container {
    min-width: 125px;
  }
}

.f-w-600 {
  font-weight: 600;
}

.break-basis {
  flex-basis: 100%;
  height: 0;
}

.pad-0 {
  padding: 0;
}

.tax-flexbox {
  display: flex;
  flex: 0 0 100%;
  margin-bottom: 25px;

  .lesson-form-group {
    flex-direction: column;
    flex-basis: inherit;
  }

  .flex-col-50 {
    padding: 0;
  }

  .flex-col-50,
  .flex-col-25 {
    align-items: flex-end;

    .mod-item {
      display: flex;
      flex: 0 0 100%;
    }
  }
}

.players-fans {
  display: flex;
  flex-direction: row-reverse;

  a {
    text-decoration: none;
    min-width: 165px;
    color: #ffffff;
    background-color: #9ad308;
    padding: 5px 0;
    font-size: 14px;
    border-radius: 3px;
    display: block;
    text-align: center;
    font-weight: normal;
    text-transform: unset;
    margin-bottom: 50px;
  }
}

.thank-you-section {
  display: block;
  justify-content: space-between;
  max-width: 40%;
  text-align: center;
  margin: auto;
}

.bookedby-container {
  .autofill- {
    margin-bottom: 15px;

    .autofill-container {
      .grouped-form {
        .form-group {
          margin-top: 0;
        }
      }

      .sign-up-btn {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
      }
    }
  }
}

.second-col {
  padding-right: 15px !important;

  .lesson-form-group {
    input {
      width: 120px;
    }
  }
}

.d-flex {
  display: flex;
}

.rbc-event-label {
  position: absolute !important;
  font-size: 10px !important;
}

.rules-container {
  position: relative;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
}

.react-tabs__tab {
  font-size: 13px;
}

.micro-settings {
  table {
    tr {
      td {
        font-size: 15px;
      }
    }
  }
}

.on-off-radio {
  .radio-label {
    flex: 0 0 45% !important;
  }

  .form-group {
    flex: 0 0 7.5% !important;

    .label-text {
      flex: auto !important;
    }
  }
}

.green-button-simple {
  display: inline-block;
  padding: 6px 10px;
  font-size: 18px;
  color: #ffffff;
  background-color: #9ad308;
  border: none;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  min-width: 90px;
}
.green-button-simple_new{
  background: #9ad308 !important;
  &:hover{
    color: #9ad308 !important;
    background: #fff !important;

    a{
      color: #9ad308 !important;
    }
    i{
      border-color: #9ad308 !important;
      left: 4%;
    }
  }
  &::after{
    background:#9ad308 !important;
  }
}
.yellow-bg {
  background: #ffbd44 !important;
}

.red-bg {
  background: #ff605c !important;
}

.micro-settings {
  .form-group {
    display: block !important;

    input[type='text'] {
      width: 100% !important;
    }
  }
}

.circle-indicator {
  width: 7px;
  height: 7px;
  background: black;
  border: 1px solid transparent;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  margin-right: 4px;
  box-shadow: 0px 0px 1px 2px #ffffff;
}

.red-circle-indicator {
  background: #ff605c !important;
}
.yellow-circle-indicator {
  background: #ffbe44 !important;
}
.green-circle-indicator {
  background: #9ad308 !important;
}
.blue-circle-indicator {
  background: blue !important;
}

.rbc-event-content {
  padding-top: 15px;
  font-size: 10px;
  position: relative;

  .circle-indicator {
    position: absolute;
    top: 4px;
    right: 0;
  }
  .circle-indicator-add-on{
    right: 10px;
  }
}

.section-extra-prop {
  padding-top: 3px;
}

.taxParth,
.credit-card-bank-account {
  margin-bottom: 10px;
}

.space-bet {
  justify-content: space-between;
}

.book-close-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addFreeTextButton{
  margin-top: 20px;
  margin-left: 6px;
}
.addFreeTextButtonDisaled{
  .jTArHl::before{
    background-color: gray;
  }
}
.m-top-0 {
  margin-top: 0 !important;
}

.scroll-buttons {
  display: flex;
  flex-direction: row-reverse;

  .next-button {
    &:hover {
      cursor: pointer;
    }
  }
}

.sign_file_div {
  max-width: 200px;
  margin-top: 15px;

  img {
    width: 100%;
  }
}

.blue-color {
  color: #1bb1e4;
}

.yellow-color {
  color: #ffbd44 !important;
}

.partners-guests {
  .member-span {
    color: #9ad308;
  }

  .guest-span {
    color: #d75a4a !important;
  }
}
.authorizedPayment{
  color: green !important;
  display: block;
  font-size: 8px;
}
.auth-container {
  .each-filter {
    margin-top: 20px;
  }
}

.members-form {
  .form-group {
    .notification {
      display: contents;
    }
  }

  .each-filter {
    .label-span {
      flex: 0 0 30%;
      max-width: 30%;
      font-weight: bold;
      text-align: left;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    .picky,
    .dp-wrapper {
      flex: 0 0 65%;
      max-width: 65%;
    }
  }
}

.additional-info-section {
  display: block;

  .additional-info-btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    display: block;
    cursor: pointer;
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    text-align: center;
    background: transparent;
    outline: none;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);

    .icon {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-left: 10px;
      transform-origin: center center;
      transition: transform 0.3s;

      &.icon-up {
        transform: rotate(90deg);
      }

      &.icon-down {
        transform: rotate(270deg);
      }
    }
  }

  .additional-info-content {
    display: none;

    &.active {
      display: block;
    }
  }
}

.covid-section {
  text-align: left;
  font-size: 18px;
  font-family: Helvetica;
  color: #f08005;
  width: 100%;
}

.facility-fee-div {
  display: flex;
  align-items: center;
  position: relative;

  .notification {
    margin-left: 15px;

    .not-icon {
      left: auto !important;
      right: auto !important;
    }

    p {
      left: auto !important;
    }
  }

  .switcherLabel {
    width: auto !important;
  }

  .facility-fee-title {
    font-weight: 600;
    text-align: left;
    font-size: 13px;
    color: #333;
  }
}

.members-list {
  table {
    .notification {
      p {
        width: 400px !important;
        margin-bottom: 21px !important;
      }
    }
  }
}

table {
  tbody {
    td {
      font-size: 12px !important;
      //font-size: 0.8vw !important;
    }
  }
}

.from-court-wrapp {
  display: flex;
  flex-wrap: wrap;
  max-width: none !important;

  .flex-col-30 {
    padding: 0 20px;
    max-width: 300px;
    width: 300px;
    display: flex;
    align-items: center;

    &:last-child {
      border-left: 1px solid #ccc;
    }
  }

  .flex-col-70 {
    display: flex;
    align-items: center;
    padding: 0 20px;
    max-width: 700px;
    width: 700px;

    &:last-child {
      border-left: 1px solid #ccc;
    }
  }
}

.align-start {
  align-items: flex-start !important;

  .form-enlarge-container {
    margin-top: 40px;
  }
}

.mod-item {
  h3 {
    font-weight: bold;
    font-size: 18px;
    padding: 5px 10px;
    background-color: #f1f1f1;
    color: #a5a5a5;
  }

  p {
    font-size: 17px;
    padding: 5px 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    span {
      flex: 0 0 50%;
      max-width: 50%;

      pre {
        font-family: sans-serif;
      }
    }
  }

  .repeatOn {
    padding: 5px 10px;

    .repeatTypeWrapper,
    .monthWrapper {
      text-align: center;
      padding: 5px 0;

      select {
        padding: 5px;
        font-size: 16px;
        border: none;
        border-bottom: 1px dotted black;
        background-color: #f9f9f9;
      }
    }

    .weekdaysWrapper {
      padding: 10px 0;
      text-align: center;

      .weekdays {
        display: inline-block;
        border-radius: 50%;
        border: 1px solid black;
        width: 30px;
        padding: auto 0;
        line-height: 30px;
        height: 30px;
        text-align: center !important;
        transition: all 0.3s;
        margin-left: 5px;
        user-select: none;
        cursor: pointer;

        &:hover {
          background-color: #a5a5a5;
        }

        &.selected {
          background-color: #9ad308;
          border: 1px solid #9ad308;
          color: #fff;
        }
      }
    }

    .monthWrapper {
      padding: 10px 0;
      text-align: center;
    }

    .DatePickerWrapper {
      text-align: center;

      td {
        padding: 5px 10px;
      }
    }
  }

  &.disabledRepiat {
    pointer-events: none !important;
    opacity: 0.4;
  }

  .repiatToggle {
    border: 1px solid #09b1e7;
    border-radius: 5px;
    display: inline-block;
    padding: 2px;
    margin: 4px 8px;
    cursor: pointer;
  }
}

.t-center {
  text-align: center;
}

.t-left {
  text-align: left !important;
}

.picky * {
  font-size: 14px;
}

.courtlisting-div {
  font-size: 14px !important;

  .lesson-form-group input,
  .sign-up-btn a,
  .sign-up-btn button,
  .green-button-simple {
    font-size: 14px;
  }
}

// }

.guest_fee_point {
  .notification {
    p {
      margin-bottom: 31px !important;
    }
  }

  .not-icon {
    top: -55px !important;
  }
}

.vrify-group {
  display: flex;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h3 {
    flex: 0 0 100%;
  }

  .verify_sub_group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 400px;
   
    input{
      width: 100% !important;
     
    }
  }
}

.d-flex-center {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.member-drop {
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
}

.w-100-between {
  width: 100%;
  justify-content: space-between;
}

.on-off {
  .form-group {
    label {
      order: unset;

      &.label-text {
        font-weight: bold;
        padding-right: 10px;
      }

      &.onoff-text {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 45%;
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        cursor: pointer;
        font-weight: bold;
      }

      &.label-icon {
        width: 44px;
        height: 24px;
        display: inline-block;
        background-color: #9ad308;

        border-radius: 12px !important;
        border: none !important;

        &:after {
          width: 20px !important;
          height: 20px !important;
          border-radius: 50%;
          left: 22px !important;
          top: 2px !important;
          background-image: none;
          background-color: #9ad308;
          opacity: 1 !important;
          transition: all 0.2s linear;
        }
      }
    }

    input {
      display: none;

      &:checked~label {
        &:after {
          left: 2px !important;
        }
      }
    }
  }
}

.tax-list-modal {
  .modal-content {
    max-width: 700px !important;
  }
}

.choose-business {
  .welcome-page {
    height: auto !important;
  }
}

.choose-business-in-modal {
  .modal-content {
    max-width: 800px !important;

    @media all and (max-width: 800px) {
      max-width: 90% !important;
    }

    .choose-business {
      .main-container {
        max-width: 100%;
      }
    }
  }
}

.christmas-box {
  h3 {
    display: block;
    max-width: 70%;
    margin: 0 auto;
    padding: 0;
    padding-top: 30px;
    color: tomato;
    text-align: center;
  }
}

.modal {
  .memberships {
    flex-direction: column !important;
  }
}

.memberships {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  flex-direction: row;
  flex-wrap: wrap;

  .membership {
    background: white;
    padding: 12px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
    margin: 20px;
    display: flex;
    flex-direction: column;
    height: 260px;
    max-width: 250px;
    cursor: pointer;

    .b-name {
      color: #282c37;
      // font-size: 20px;
      text-transform: capitalize;
      text-align: left;
      width: 100%;
      margin-top: 10px;
    }

    .img {
      max-width: 100%;
      width: 235px;
      height: 170px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

.lesson_cancel_test {
  font-size: 85%;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.picky__placeholder {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 95%;
}

input.error {
  border-color: #ff0000 !important;
}

.email-info {
  span.not-icon {
    left: 20%;
    top: -30px;
  }

  p {
    left: 20%;
    bottom: 120%;
  }
}

.close-btn {
  text-align: right;
  padding: 5px 0;

  button {
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
    font-size: 18px;
  }
}

.save_card_div {
  .form-group {
    display: flex;

    input {
      width: 20px;
      height: 20px;
    }

    label {
      width: 250px;
    }
  }
}

.sign-group {
  .form-group {
    flex: 0 0 10%;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
  }
}

.point-container,
.freeze-container {
  .from-to {
    margin-bottom: 75px;
  }

  .types-container {
    display: flex;
    align-items: center;
    font-size: 22px;
    text-transform: capitalize;
    margin-top: 35px;

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-right: 35px;

      :focus {
        outline: none;
      }

      .radio {
        -webkit-appearance: button;
        -moz-appearance: button;
        appearance: button;
        border: 2px solid #ccc;
        border-top-color: #bbb;
        border-left-color: #bbb;
        background: #fff;
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }

      .radio:checked {
        border: 10px solid #4099ff;
      }
    }
  }
}

.mr-2 {
  margin-right: 10px;
}

.mx-auto {
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.justify-center {
  justify-content: center;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  border: none;
  line-height: 1;
  padding: 5px;
  background: transparent;
  outline: none;

  &.btn-green {
    color: #ffffff;
    background-color: #9ad308;
  }

  &.btn-red {
    color: #ffffff;
    background-color: tomato;
  }

  &.btn-gray {
    color: #ffffff;
    background-color: #465860;
  }

  &.rounded {
    border-radius: 50%;
  }

  img {
    display: block;
  }
}

.report_uncollected_partner {
  .form-group {
    justify-content: space-between;
  }

  label.onoff-text {
    flex: 0 0 36% !important;
    cursor: pointer;

  }
}

.dynamicFormCounter {
  position: absolute;
  right: 0;
  bottom: -25px;
  z-index: 0;
  font-size: 14px;
}

.counter_cutum {
  font-size: 10px;
  float: right;
  display: block;
}

.counter_cutum+div::after {
  content: "";
  display: block;
  clear: both;
}

.business_tax_rate {
  padding: 0 !important;
}

.refound_title {
  margin-bottom: 0 !important;
}

.refound_btn {
  display: block;
  margin: auto !important;
}

.lessonMinMax {
  width: 90%;
  margin: auto;

  .btn {
    border-radius: 2px;
  }

  input {
    margin-left: 2%;
  }

  label {
    width: 50px;
  }
}

.removeCoursorsForSafary,
* {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.total_count_customers {
  font-size: 12px;
  margin-top: 8px;
  margin-left: 30px;
}

.groupItemMidelColume {
  div {
    display: inline-block;
  }

  div:first-child {
    width: 80%;
  }
}

.showLessonMinMax {
  overflow: visible !important;
  height: auto !important;

  * {
    visibility: visible !important;
  }
}

.lessonType_custom {
  button {
    border-radius: 2px !important;
  }
  .btn-extra_color{
    max-height: 29px !important;
  }
}

.lessonMinMaxLable {
  overflow: hidden;
  margin-top: 3%;
  height: 30px;

  padding-top: 1%;
  padding-bottom: 1%;

  h4 {
    text-align: left;
    margin-bottom: 2%;
    font-weight: 500;
    margin-left: 2%;
    border-radius: 2px;
    cursor: pointer;
    position: relative;

    span {
      position: absolute;
      transform: rotate(179deg);
      display: block;
      float: right;
      margin-right: 10%;
      font-weight: bolder;
      font-size: 12px;
      font-family: cursive;
      visibility: visible !important;
      top: 6px;
      left: 15%;
    }
  }


  *:not(h4) {
    visibility: hidden;
    transition: all .2s;
  }

  label {
    display: inline-block;
  }

}

.dounload_members {
  margin-left: 20px;
  float: right;

  button {
    background-color: #000000ab !important;
  }

  button:hover {
    color: #000000ab !important;
  }

  button:before,
  button:after {
    background-color: #000000ab !important;
  }
}

.group_table_single {
  thead {
    tr {
      th:nth-child(4) {
        width: 10%;
      }
    }
  }
}

.group_table {
  thead {
    tr {
      th:nth-child(1) {
        width: 20%;
      }

      th:nth-child(2) {
        width: 63%;
      }

      th:nth-child(3) {
        width: 10%;
      }

      th:nth-child(4) {
        width: 7%;
      }
    }
  }

  tbody {
    td{
      padding: 0% !important;
    }
    tr>td:first-child>a, tr>td:nth-child(3)>a{
      padding: 9% 0%;
    }
    td>a {
      text-decoration: none;
      display: block;
      padding: 3% 0%;
    }

    * {
      transition: all .5s;
    }

    tr:hover {
      background-color: #e1e1e15c;
    }
  }
}
.staff_table{
  tbody{
    * {
      transition: all .5s;
    }
    tr:hover {
      background-color: #e1e1e15c;
    }
  }
}

.groups_options_parent {
  position: relative;



  &:hover {


    .groups_options {
      display: block;
      visibility: visible;
    }
  }

  .groups_option_points {
    font-weight: bolder;
    font-size: 25px;
    font-family: cursive;
    transform: rotate(90deg);
    margin-left: 10%;
    cursor: pointer;
  }

  .groups_options {
    transition: all .5s;
    display: none;
    visibility: hidden;
    position: absolute;
    background: #ffffff;
    width: 300%;
    z-index: 9;
    left: -247%;
    border-radius: 6px;
    box-shadow: 0 0 5px 0px #000;



    .popListItem {
      display: block;

      // padding: 4%;
      // margin-top: 3%;
      // margin-bottom: 4%;


      button,
      a,
      div {
        margin: 0;
      }


      .delete_group_btn {
        margin: 0;
        display: inline-block;
        float: right;

        img {
          width: 12px;
          height: 12px;
        }
      }

      .download_group,
      a {
        background-color: #ffffff;
        display: inline-block;
        border-radius: 5px;
        padding: 4% 4% 3% 8%;
        cursor: pointer;
        text-decoration: none;
        display: block;
        text-align: left;


        &:hover {
          background-color: #e1e1e1d1;
        }

        span {
          vertical-align: super;
          margin-right: 3px;
        }

        img {
          width: 20px;
          height: 20px;
          float: right;
        }
      }

      .dounload_members {
        float: none;
      }
    }
  }
}

.initation_and_subsctiption_options_parent {
  position: relative;
  padding-left: 2%;
  padding-right: 2%;

  &:hover {


    .groups_options {
      display: block;
      visibility: visible;
    }
  }

  .groups_option_points {
    font-weight: bolder;
    font-size: 25px;
    font-family: cursive;
    transform: rotate(90deg);
    margin-left: 10%;
    cursor: pointer;
  }

  .groups_options {
    transition: all 1s;
    display: none;
    visibility: hidden;
    position: absolute;
    background: #ffffff;
    width: 970%;
    z-index: 9;
    right: 10px;
    border-radius: 6px;
    box-shadow: 0 0 5px 0px #000;
    top: 70%;

    .popListItem {
      display: block;
      padding: 4%;
      margin-top: 3%;
      //  margin-bottom: 4%;

      .chargeButton {
        float: none;
      }

      button,
      a,
      div {
        margin: 0;
      }


      .delete_group_btn {
        margin: 0;
        display: inline-block;
      }

      .download_group,
      a {
        background-color: #ffffff;
        display: inline-block;
        border-radius: 5px;
        padding: 4% 4% 3% 8%;
        cursor: pointer;
        text-decoration: none;
        display: block;
        text-align: left;
        color: #000000;

        &:hover {
          background-color: #e1e1e1d1;
        }

        span {
          font-size: 12px;
          vertical-align: super;

        }

        img {
          width: 20px;
          height: 23px;
          float: right !important;
        }
      }

      .dounload_members {
        float: none;
      }
    }
  }
}

.profImage {
  width: 300px;
  min-height: 250px;
}
.serviceTypesSelect{
  width: 427px;
  margin: auto;
  
  span{
    font-weight: bold;
    vertical-align: sub;
  }
  div{
   float: right;
  }
}
.lessonTypeClassBtn{
  background-color: #1aab8a;
  &:hover{
   // background: #1aab8a !important;
  }
}
.archiveBtnOfLesson{
  float: right;
  position: relative;
  margin-right: 5%;

  .archive{
    height: 26px !important;
  }
  
}
.archivedTypesBtn{
  position: absolute;
  top: 0;
  right: -30%;
  width: 150px;

  button{
 border-radius: 3px !important;
  }
}

// .settings-container{
//   input:-webkit-autofill,
//   input:-webkit-autofill:hover,
//   input:-webkit-autofill:focus,
//   input:-webkit-autofill:active {
//     visibility: hidden !important;
//     display: none !important;
//     pointer-events: none !important;
//     position: absolute !important;
//     right: 0 !important;
//   }
//   input::-webkit-autofill,
//   input::-webkit-contacts-auto-fill-button,
//   input::-webkit-credentials-auto-fill-button{
//     visibility: hidden !important;
//     display: none !important;
//     pointer-events: none !important;
//     position: absolute !important;
//     right: 0 !important;
//   }
  
  
// }
.ownerView{
  width: 65%;
  margin: auto;
}
.facility_availability_rules_btn{
  background-color: rgba(223, 240, 255, 0.65) !important;
  color: #077ddf !important;
}
.bulk_email_editor{
  background-color: #fff;
  padding: 1%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px;
  min-height: 250px;

  
}

.bulk_email_editor_toolbar{
  [title=Strikethrough]{
    display: none;
  }
  [title=Monospace]{
    display: none;
  }
  [title=Indent]{
    display: none;
  }
  [title=Remove]{
    display: none;
  }
  [title=Outdent]{
      display: none;
    }
  [title=Left]{
    display: none;
  }
  [title=Center]{
    display: none;
  }
  [title=Right]{
    display: none;
  }
  [title=Justify]{
    display: none;
  }

  [title=Superscript]{
      display: none;
    }

    [title=Subscript]{
      display: none;
    }
    .rdw-block-wrapper{
      display: none;
    }
    .rdw-fontfamily-wrapper{
      display: none;
    }
  .rdw-colorpicker-wrapper{
        display: none;
      }

  .rdw-embedded-wrapper{
    [title=Embedded]{
      display: none;
    }
  }
  .rdw-image-wrapper{
    [title=Image]{
      display: none;
    }
  }
}
.reportPremium{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
}
.micro_repeat{
  .switcherLabel{
    display: block;
    width: 100%;

    label,span{
      margin-left: 3%;
    }
    label{
      vertical-align: middle;
    }
  }
}
[data-placement="bottom-ends"]{
  left: 50px !important;
}
.colorPicker{
  button{
    margin-top: 6px !important;
  }
  span{
    font-weight: bold;
  }
}

.card-title_costom{
  font-size: 1rem !important;
}
.bb-background{
  .css-3mfg68-Icon{
    background: #97ca3f !important;
  }
}
.heartTest{
  span{
    font-size: 24px !important;
  }
}
@keyframes changingTextColors {
  from  {color:#9ad308;}
  to  {color:#b7ff00;}
 }
.terminalMessigeFullScreen{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000b3;
  border-radius: 10px;
  display: none;

  center{
    display: block;
    margin: auto;
    color: #9ad308;
    font-size: 20px;
    line-height: 200px;
    height: 200px;
    animation-name: changingTextColors;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
  }
}
.rbc-time-view-resources{
  .rbc-time-gutter{
    z-index: 5 !important;
  }
  .rbc-time-header-gutter{
    z-index: 5 !important;
  }
}
input[type=email], input[name=email],input[name=userName]{
  text-transform: lowercase !important;
}
.paymentInfoIcon{
  position: absolute;
  top: 5px;
  left: 23px;
  p{
    margin-bottom:22px;
  }
}
.saveCard{
  display: block;
  text-align: left;
  input{
    display: inline-block;
    width: auto !important;
  }
}
.invoiceTable{
  tr:nth-child(even) {background: #CCC}
}
.waitlist_mes{
  display: block;
  font-size: 12px;
  padding: 3%;
  color: #ffffff;
  background-color: orange;
  font-weight: 600;
}
.priceContentNBU{
  pointer-events: none;
}
.freezeTimeRange{
  flex: 0 0 70%;
  display: flex;
  justify-content: space-between;
  .lesson-start-time{
    flex: 0 45%;
  }
}
.lesson-create-wrap_new{
  padding-top: 0%;
  .left-side{
    padding: 0% !important;
  }
  .camps-section-option {
    font-size: 13px;
    .form-group {
      justify-content: flex-end !important;
      align-items: center !important;
      label{
        font-size: 13px;
      }
    }
   
  }
  .lessons-note{
    textarea{
      height: 70px !important;
    }
  }
  .borderedBlock{
    background: #fff;
    padding: 2%;
    border-radius: 5px;
    box-shadow: 0px 0px 6px #b2b2b2;
    border: 0.1px solid;
    margin-bottom: 8px;
  }
  .lesson-form-group{
    padding-top: 0px;
    label{
      font-size: 13px !important;
      font-weight: bold;
    }
  }
  ._rec-form{
    .form-group{
      margin-top: 0px !important;
      label{
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
  .lessons-note{
    h5{
      font-size: 13px;
    }
  }
  .scroll-container-dates{
    margin-bottom: 0%  !important;
    .dates-table{
      margin-top: 0 !important;
      
      .addMultipleButton{
        font-size: 13px !important;
        font-weight: bold;
        padding-top: 0px;
        padding-bottom: 0px;
      }
      span{
        font-weight: bold;
      }
      .plus-icon{
        margin-left: 0!important;
        margin-top: 8px;
        
        button{
          padding: 0px !important;
          width: 20px !important;
          height: 20px !important;
          img{
            max-width: 9px;
          }
        }
      }
    }
  }
  .thirth-col{
    .modal-title{
      text-align: left;
    }
  }
}
.plans-table_remove{
  table{
    tbody{
      tr[disabled]{
        background-color: #ffffff !important;
      }
    }
  }
}
.zoomInClassLesson{
  transform: scale(0.9);
  margin-top: -5px !important;
}
.micro-settings-new-style{
  transform: scale(0.9);
  margin-top: -5px !important;
  .lesson-form-group{
    padding-top: 5px !important;
  }
  .btn-green{
    img{
      width: 15px;
      height: 15px;
    }
  }
  .mod-item{
    *{
      font-size: 13px !important;
    }
  }
  .form-group{
    margin-bottom: 3px !important;
    margin-top: 0 !important;
    *{
      font-size: 13px !important;
    }
  }
  .rec-form{
    padding-bottom: 5px;
    
    .form-group{
      margin-bottom: 3px !important;
      margin-top: 0 !important;
    }
    *{
      font-size: 13px !important;
    }
  }
}
@media (max-width: 768px) {
  
  .small_for_mobile{
    transform: scale(0.8);
   
  }
  .header-menu{
    .nav-bar{
      ul{
        li{
          padding: 8px 0 !important;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  
  .lesson-end-time{
    .react-datepicker-popper{
      left: -40% !important;
      top: 25px !important;
    }
    .react-datepicker__triangle{
      right: 50px !important;
      left: auto;
    }
  }
  
  
}
.noFlex{
  display: inline-block !important;
}
.smallTextTitleToPlusBtn{
  font-size: 9px;
  color: #000000;
  height: 0;
  margin-top: 3px;
  text-align: center;
}
.btn_item{
  width: 13%;
  display: flex;
  flex-direction: column;
    align-items: center;
}
.font-size-13{
  font-size: 13px !important;
}
.font-size-11{
  font-size: 11px !important;
}
.pricing_part ,.pricePageScale{
  transform: scale(0.9);
}
.pricing_part{
  margin-top:  100px;
}
.pricePageScale{
  margin-top: -140px;
}
.marginTop-100{
  margin-top: -100px !important;
}
.main_btn{
  transform: scale(0.9);
}
.yogaPagePrice{
  .pricePageScale{
    margin-top: -240px;
    margin-bottom: -200px;
  }
}
.isCoachMemberItem{
  img{
    width: 25px;
    float: right;
  }
}
.modal-checkins {
  
  input{
    font-size: 14px !important;
  }
}
.css-yk16xz-control,.css-1hwfws3{
  height: 33px !important;
  border-color: #606948 !important;

  svg{
    color: #606948 !important;
  }
}
.css-1uccc91-singleValue{
  top:60% !important;
}
.margin_left_11{
  margin-left: 11px;
}
.waitlistAutoFill{
  .autofill-container,.input-drop{
    justify-content: flex-end;
  }
  .input-drop{
    margin-right: 5%;
  }
}
.prentElementForInfoBtn{
  position: relative;
  .expiredRules{
    position: absolute;
    right: -22px;
    top:0;
    background-color: red;
    color: #ffffff;
   //padding: 1%;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
  }
  img{
    position: absolute;
    right: -18px;
    top:0;
  }
}
.expiredRulesText{
  font-size: 10px;
  color: red;
}
.pf-button{
  border: 0.5px solid #552583!important;
  background: #552583!important;
 
  font-size: 16px!important;
  padding: 6px 25px!important;
  border-radius: 48px!important;
  color: #fff;
  cursor: pointer;
  margin-top: -6px;
  position: absolute;
  transition: all 1s;
  //box-shadow: 0 0 5px #3c3bb8;
  @media (max-width:1024px) {
    font-size: 13px!important;
    padding: 3px 15px !important;
  }
  &:hover{
    text-shadow: 0 0 1px white;
    box-shadow: 0 0 1px #3c3bb8;
  }
}
.pf_btn_li{
  position: relative;
  //width: 135px;
  width: 225px;
  @media (max-width:1024px) {
    width: 100px;
  }
  @media (max-width:768px) {
    width: 100%;

    .pf-button{
      position: unset;
      display: block;
      margin: auto;
    }
  }
}
.pf-button-s{
  text-decoration: none;
  border: 0.5px solid #9ad308!important;
  background: #9ad308!important;
  font-size: 16px!important;
  padding: 6px 25px!important;
  border-radius: 48px!important;
  color: #fff;
  cursor: pointer;
  transition: all 1s;
  //box-shadow: 0 0 5px #3c3bb8;
  &:hover{
    text-shadow: 0 0 1px white;
    box-shadow: 0 0 11px #9ad308;
  }
}
.pfmodal{

  .modal-content{
    overflow: hidden;

    .copyIcon{
      width: 17px;
      margin-left: 5px;
      cursor: pointer;
    }
    .modal-text{
      padding-top: 3%;
      h2{
        color: #9ad308 ;
      }
      .pTagWithCopy{
        position: relative;
        cursor: pointer;

        .copyStatus{
          position: absolute;
          font-weight: bold;
          top: -3px;
          right: -40px;
          font-size: 9px;
        }
      }
      .textP{
        font-size: 14px;
        text-align: left;
        padding: 1% 10%;

        ul{
          display: block;
          width: 75%;
          margin-left: 27px;
        }
      }
    }
  }
}
.pfmodalheader{
  background-color: #9ad308;
  padding: 4%;
  margin-top: -10px;
  margin-left: -10px;
  width: 105%;
  img{
    width: 30%;
  }
}
.filterAndLocationDropdown{
  max-width: 270px;

  @media (max-width:1024px) {
    max-width: 200px;
  }
}
.schedule-demo-container_with_border{
  
  .schedule-demo-container{
    border: 1px solid #9ad307;
  }
 
}
.widgetContent{
  padding-top: 0%;
  .widgetHeaer{
    padding: 10px;
    .pfmodalheader{
      img{
        display: block;
        margin: 0 auto;
      }
    }
  }
  .widgetTitle{
    h2{
      color: #9ad307;
    }
  }
  .widgetText{
    padding: 20px;
    p{
      font-size: 14px;
    } 
  }
}
iframe{
 // display: none !important;
}